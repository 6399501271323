import { Link } from 'react-router-dom'

export const Hero = () => {
  return (
    <div className="space-y-10 px-8 py-36 text-center">
      <div className="space-y-2">
        <h1 className="text-5l text-white md:text-[5rem]">
          Smart Investing
          <br />
          <span className="text-[2.0rem] font-bold text-orange-500 md:text-[5rem]">
            Starts Here
            {/* <span className="underline decoration-4 underline-offset-[15px] decoration-[#FE5F00]">
              ere
            </span> */}
          </span>
        </h1>
      </div>
      <div className="mx-auto w-full md:w-1/2">
        <p className="text-sm font-semibold text-white md:text-xl md:font-bold">
          Tickerzone monitors your investments for you, and delivers insights
          tailored to your portfolio and risk tolerance, helping you make
          decisions aligned with your investment strategy.
          {/* Introducing your <span className="text-orange-500 font-bold">AI advisor</span>: Constantly monitoring 
        your stock holdings and delivering personalized insights 
        tailored to your unique risk tolerance, 
        ensuring informed and strategic decisions. */}
        </p>
      </div>
      <div>
        <Link
          to="/auth/create-new-account/"
          className="rounded-md bg-flamingo-500 px-3 py-2 text-xs font-medium uppercase text-white"
        >
          Get started
        </Link>
      </div>
    </div>
  )
}
