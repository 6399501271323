// @ts-nocheck
import { useAuth } from 'lib/auth'
import { useNavigate } from 'react-router-dom'
import { useCallback, useState, useEffect } from 'react'
import { usePlaidLink } from 'react-plaid-link'
import { CreatePortfolio as CreatePortfolioDrawer } from 'features/portfolios/components/CreatePortfolio'
import { QuickstartProvider } from 'features/connect/context/index'
import { transmitToken } from 'features/connect/api/transmitToken'
import { useFetchLinkToken } from 'features/connect/api/fetchLinkToken'
import { fetchSupportedBrokerages } from 'features/connect/api/fetchSupportedBrokerages'
import storage from 'utils/storage'
import { AuthLayout } from '../components/AuthLayout'
import { Button } from 'components/elements'
// import { CreatePortfolio } from 'features/portfolios/components/CreatePortfolio';

export const PortfolioLanding = () => {
  const { isAuthenticated, user } = useAuth()

  const [clicked, setClicked] = useState(false)
  const [supportedBrokerages, setSupportedBrokerages] = useState([])
  const { linkToken, fetchLinkToken, isLoading, error } = useFetchLinkToken()
  const navigate = useNavigate()

  useEffect(() => {
    fetchLinkToken() // Fetch token when the component mounts
    fetchSupportedBrokerages().then(setSupportedBrokerages).catch(console.error)
  }, [fetchLinkToken])

  console.log(linkToken)

  const onSuccess = useCallback(
    async (public_token, metadata) => {
      try {
        const response = await transmitToken({ public_token })
        // const portfolios = await getPortfolios();
        storage.setActivePortfolio(response.info.portfolio_id)
        navigate('/app/portfolios/create/success')
        console.log(response) // Or handle the response as needed
      } catch (error) {
        console.error('Error transmitting token:', error)
        navigate('/app/portfolios/create/failure')
      }
    },
    [navigate]
  )

  const onExit = useCallback(
    (err, metadata) => {
      console.error('Plaid Link closed by user or error:', err)
      navigate('/app/portfolios/create/failure')
    },
    [navigate]
  )

  const config = linkToken
    ? {
        token: linkToken,
        onSuccess,
        onExit,
      }
    : {}

  const { open, ready } = usePlaidLink(config)

  const onClickHandler = () => {
    if (ready && !clicked) {
      open()
      setClicked(true)
    }
  }

  if (isLoading) return <p>Loading...</p>
  if (error) return <p>Error fetching link token: {error}</p>

  return (
    <AuthLayout option="login">
      <div className="">
        <h1 className="my-12 text-4xl font-bold">
          Welcome, {user.first_name}{' '}
        </h1>
        <div className="md:mr-24">
          <div className="text-2xl font-bold">Link your Account</div>
          <div className="my-2">
            Before we proceed to your dashboards, how would you like to create
            your first portfolio?
          </div>
          <div className="grid grid-cols-1 gap-8 md:grid-cols-2">
            <div className="space-y-4 rounded-md bg-gray-100 px-12 py-24 text-gray-500 dark:bg-dim-200 dark:text-gray-200">
              <div className="font-black">Securely link your account.</div>
              <ul>
                <p className="h-32">
                  We prioritize your security and privacy. All your account and
                  brokerage information is encrypted, and we never have access
                  to it.
                </p>
                {/* {supportedBrokerages.map(brokerage => (
                  <li key={brokerage}>{brokerage}</li> // Display each supported brokerage
                ))} */}
              </ul>
              <QuickstartProvider>
                <Button
                  className="w-full py-5"
                  variant="outline"
                  onClick={onClickHandler}
                  disabled={clicked || !linkToken}
                >
                  {clicked ? 'Connecting...' : 'CONNECT'}
                </Button>
              </QuickstartProvider>
            </div>

            <div className="space-y-4 rounded-md bg-gray-100 px-12 py-24 text-gray-500 dark:bg-dim-200 dark:text-gray-200">
              <div className="font-black">Track your holdings manually</div>
              <p className="h-32">
                Add your holdings information by simply adding a ticker,
                quantity of shares, and average price per share.
              </p>
              <CreatePortfolioDrawer title="CREATE PORTFOLIO" />
            </div>
          </div>
        </div>
      </div>
    </AuthLayout>
  )
}
