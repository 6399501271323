// @ts-nocheck
import { axios } from 'lib/axios';
import { queryClient } from 'lib/react-query';
import { useNotificationStore } from 'stores/notifications';
import { useMutation } from 'react-query';

export const editProfile = ({ data, id }: { data: any; id: string }) => {
  return axios.put(`/auth/update_profile/${id}/`, data);
};

export const useEditProfile = ({ config = {} } = {}) => {
  const { addNotification } = useNotificationStore();

  return useMutation({
    onMutate: async updatingProfile => {
      await queryClient.cancelQueries(['accountDetails', updatingProfile?.id]);

      const previousProfile =
        //@ts-ignore
        queryClient.getQueryData[('accountDetails', updatingProfile?.id)];

      queryClient.setQueryData(['accountDetails', updatingProfile?.id], {
        ...previousProfile,
        ...updatingProfile.data,
        id: updatingProfile.id,
      });

      return { previousProfile };
    },
    onError: (_, __, context) => {
      if (context?.previousProfile) {
        queryClient.setQueryData(
          ['accountDetails', context.previousProfile.id],
          context.previousProfile
        );
      }
    },
    onSuccess: data => {
      queryClient.refetchQueries(['accountDetails']);
      addNotification({
        type: 'success',
        title: 'Profile Updated',
      });
    },
    ...config,
    mutationFn: editProfile,
  });
};
