// @ts-nocheck
import { Table } from 'components/elements';
import { PriceChart } from 'components/charts';
import { UpdateOptionDetails } from './UpdateOptionDetails';
import { UpdateOptionPosition } from './UpdateOptionTrade';
import { DeleteActiveTrade } from './DeleteActiveTrade';
import { roundOff } from 'lib/round-off';
import { formatPrice } from 'utils/formatTargetPrice';
import { BiDotsVertical } from 'react-icons/bi';
import { Menu, MenuItem, MenuButton } from '@szhsin/react-menu';
import { useColorModeContext } from 'context/ColorContext';

// Import styles
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';
import '@szhsin/react-menu/dist/theme-dark.css';

export const OptionHoldings = ({ data }) => {
  const [darkMode] = useColorModeContext();
  const ticker_type = 'option';

  const columns = [
    // Essential Contract Info
    {
      title: 'Ticker',
      field: 'ticker',
      Cell({ entry: { ticker } }) {
        return <>{ticker}</>;
      },
    },
    {
      title: 'Strike', // Clear and concise
      field: 'strike_price',
      Cell({ entry: { strike_price } }) {
        return <>${formatPrice(strike_price)}</>;
      },
    },
    {
      title: 'Type', // For Put/Call
      field: 'option_type',
      Cell({ entry: { option_type } }) {
        return <span className="capitalize">{option_type}</span>;
      },
    },
    {
      title: 'Expiry', // Industry standard term
      field: 'expiration_date',
      Cell({ entry: { expiration_date } }) {
        if (!expiration_date) return '--';
        try {
          const date = new Date(expiration_date);
          return date.toISOString().split('T')[0];
        } catch (e) {
          return '--';
        }
      },
    },

    // Position Details
    {
      title: 'Price', // Current price
      field: 'price',
      Cell({ entry: { price } }) {
        return <>${formatPrice(price)}</>;
      },
    },
    {
      title: 'Contracts', // Instead of 'Quantity'
      field: 'quantity',
      Cell({ entry: { quantity } }) {
        return <>{quantity}</>;
      },
    },
    {
      title: 'Gain', // Performance metric
      field: 'percentage_gain',
      Cell({ entry: { percentage_gain } }) {
        return <>{roundOff(percentage_gain, 0.01)}%</>;
      },
    },

    // Greeks (Essential for Options)
    {
      title: 'Delta',
      field: 'delta',
      Cell({ entry: { delta } }) {
        return (
          <span title="Price change per $1 move in underlying">
            {delta ? delta.toFixed(2) : '--'}
          </span>
        );
      },
    },
    {
      title: 'Theta',
      field: 'theta',
      Cell({ entry: { theta } }) {
        return (
          <span title="Time decay per day">
            {theta ? theta.toFixed(3) : '--'}
          </span>
        );
      },
    },
    {
      title: 'IV', // Keep acronym as is
      field: 'iv',
      Cell({ entry: { iv } }) {
        return (
          <span title="Implied Volatility">
            {iv ? `${(iv * 100).toFixed(1)}%` : '--'}
          </span>
        );
      },
    },

    // Target Info
    {
      title: 'Target', // Shortened from 'Price Target'
      field: 'price_target',
      Cell({ entry: { price_target } }) {
        return <>${roundOff(price_target, 0.01)}</>;
      },
    },

    // Visual Aid
    {
      title: 'Chart',
      field: 'id',
      Cell({ entry: { entry_price, price_target, price, quantity } }) {
        return (
          <PriceChart
            entryPrice={entry_price}
            targetPrice={price_target}
            currentPrice={price}
            quantity={quantity}
          />
        );
      },
    },
    {
      title: '',
      field: 'id',
      Cell({ entry }) {
        const {
          id,
          quantity,
          entry_price,
          price_target,
          notif_trigger_pct_gain_low,
          notif_trigger_pct_gain_high,
          action,
          ticker,
        } = entry;

        return (
          <Menu
            theming={darkMode ? 'dark' : ''}
            menuButton={
              <MenuButton>
                <BiDotsVertical className="h-4" />
              </MenuButton>
            }
            transition
          >
            <MenuItem>
              <UpdateOptionDetails
                pk={id}
                quantity={quantity}
                entry_price={entry_price}
                price_target={price_target}
                ticker={ticker}
                notif_trigger_pct_gain_low={notif_trigger_pct_gain_low}
                notif_trigger_pct_gain_high={notif_trigger_pct_gain_high}
              />
            </MenuItem>
            <MenuItem>
              <UpdateOptionPosition
                pk={id}
                avg_price={entry_price}
                quantity={quantity}
                ticker={ticker}
                price_target={price_target}
                action={action}
              />
            </MenuItem>
            <MenuItem>
              <DeleteActiveTrade ticker_type={ticker_type} tradeId={id} ticker={ticker} />
            </MenuItem>
          </Menu>
        );
      },
    },
  ];

  return <Table data={data} columns={columns} />;
};
