import { nanoid } from 'nanoid'
import { create } from 'zustand'

export const useNotificationStore = create(set => ({
  notifications: [],
  addNotification: (notification: any) =>
    set((state: any) => ({
      notifications: [
        ...state.notifications,
        { id: nanoid(), ...notification },
      ],
    })),
  dismissNotification: (id: any) =>
    set((state: any) => ({
      notifications: state.notifications.filter(
        (notification: any) => notification.id !== id
      ),
    })),
}))
