// @ts-nocheck
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from 'lib/auth'
import { Button } from 'components/elements'
import { AuthLayout } from '../components/AuthLayout'
import { InputFieldV2 } from 'components/form/InputFieldv2'
import validator from 'validator'
import { useRegisterWithEmailAndPassword } from '../api/register'

export const CreateAccount = () => {
  const { register } = useAuth()
  const navigate = useNavigate()
  const registerMutation = useRegisterWithEmailAndPassword()

  const [firstname, setName] = useState('')
  const [email, setEmail] = useState('')
  const [password1, setPassword] = useState('')
  const [password2, setPassword2] = useState('')
  const [passwordError, setPasswordError] = useState('')

  const [passwordValid, setPasswordValid] = useState({
    minLength: false,
    minUppercase: false,
    minLowercase: false,
    minNumbers: false,
    minSymbols: false,
    passwordsMatch: false,
  })

  useEffect(() => {
    const validatePasswordRequirements = () => {
      setPasswordValid({
        minLength: password1.length >= 8,
        minUppercase: /[A-Z]/.test(password1),
        minLowercase: /[a-z]/.test(password1),
        minNumbers: /[0-9]/.test(password1),
        minSymbols: /[!@#$%^&*(),.?":{}|<>]/.test(password1),
        passwordsMatch: password1 === password2,
      })
    }

    validatePasswordRequirements()
  }, [password1, password2])

  const validatePasswordMatch = () => {
    if (firstname.length < 1) {
      setPasswordError('First name must not be empty.')
      return false
    }
    if (!validator.isEmail(email)) {
      setPasswordError('Enter a valid Email.')
      return false
    }
    if (
      !passwordValid.minLength ||
      !passwordValid.minUppercase ||
      !passwordValid.minLowercase ||
      !passwordValid.minNumbers ||
      !passwordValid.minSymbols
    ) {
      setPasswordError('Password does not meet all requirements.')
      return false
    }
    if (!passwordValid.passwordsMatch) {
      setPasswordError('Passwords do not match')
      return false
    } else {
      setPasswordError('')
      return true
    }
  }

  const handleNameChange = e => {
    setName(e.target.value)
  }

  const handleEmailChange = e => {
    setEmail(e.target.value)
  }

  const handlePasswordChange = e => {
    setPassword(e.target.value)
  }

  const handlePassword2Change = e => {
    setPassword2(e.target.value)
  }

  const handleRegistration = async () => {
    if (!validatePasswordMatch()) {
      return // Don't proceed if passwords don't match
    }

    if (password1 && email) {
      const password = password1
      const first_name = firstname
      const response = await registerMutation.mutateAsync({
        first_name,
        email,
        password,
        password2,
      })

      if (response.email === email) {
        // Registration successful
        navigate('/auth/create-new-account/thank-you')
      }
    }
  }

  const className =
    'border border-solid bg-gray-50 border-gray-300 rounded-full w-full outline-none focus:outline-none px-8 py-1.5'

  return (
    <AuthLayout option="create-new-account">
      <div className="">
        <h1 className="my-12 text-4xl font-bold">Create your account</h1>
        <form className="flex w-full flex-col items-center gap-6">
          <InputFieldV2
            label={firstname}
            onChange={handleNameChange}
            type="string"
            className={className}
            placeholder="First name"
            maxLength={50}
          />
          <InputFieldV2
            value={email}
            onChange={handleEmailChange}
            type="email"
            className={className}
            placeholder="Email address"
            maxLength={50}
          />
          <InputFieldV2
            value={password1}
            onChange={handlePasswordChange}
            type="password"
            className={className}
            placeholder="Password"
            maxLength={50}
          />
          <InputFieldV2
            value={password2}
            onChange={handlePassword2Change}
            type="password"
            className={className}
            placeholder="Confirm Password"
            maxLength={50}
          />
          <div className="text-sm text-gray-600">
            <p>Password must contain:</p>
            <ul className="list-inside list-disc">
              <li
                className={
                  passwordValid.minLength ? 'text-green-500' : 'text-red-500'
                }
              >
                At least 8 characters
              </li>
              <li
                className={
                  passwordValid.minUppercase ? 'text-green-500' : 'text-red-500'
                }
              >
                At least 1 uppercase letter
              </li>
              <li
                className={
                  passwordValid.minLowercase ? 'text-green-500' : 'text-red-500'
                }
              >
                At least 1 lowercase letter
              </li>
              <li
                className={
                  passwordValid.minNumbers ? 'text-green-500' : 'text-red-500'
                }
              >
                At least 1 number
              </li>
              <li
                className={
                  passwordValid.minSymbols ? 'text-green-500' : 'text-red-500'
                }
              >
                At least 1 symbol
              </li>
            </ul>
          </div>
          {passwordError && <div className="text-red-500">{passwordError}</div>}
          <Button onClick={handleRegistration}>Register</Button>
        </form>
      </div>
    </AuthLayout>
  )
}
