// @ts-nocheck
import { Link } from 'react-router-dom'
import { Heading } from 'components/layout/components/Heading'
import novice_investor from 'assets/tickerzone_streamlines.png'
import risky_exposure from 'assets/risk_exposure.jpeg'
import target_setting from 'assets/target_setting.jpeg'

export const Portfolio = () => {
  return (
    <div className="mx-auto my-8 -mt-0 w-5/6 pb-8 pt-20 md:mb-14">
      <Heading color="black">
        Track, analyze, and grow your investments.
      </Heading>
      <div className="my-8 grid grid-cols-1 gap-4 lg:grid-cols-3 lg:gap-12">
        <div className="text-center">
          <img
            src={novice_investor}
            alt="charts"
            className="mx-auto my-2 h-40 w-auto rounded-md shadow-lg"
          />
          <h3>Streamline decision-making</h3>
          <p className="px-4">
            Tickerzone helps you make informed decisions and by tracking assets
            against your performance expectations.
          </p>
        </div>
        <div className="text-center">
          <img
            src={risky_exposure}
            alt="board"
            className="mx-auto my-2 h-40 w-auto rounded-md shadow-lg"
          />
          <h3>Stay Informed, Not Obsessed.</h3>
          <p className="px-4">
            Strict monitoring and alerts allows you to course-correct when
            needed and gives you the freedom to focus on what matters the most
          </p>
        </div>
        <div className="text-center">
          <img
            src={target_setting}
            alt="real estate"
            className="mx-auto my-2 h-40 w-auto rounded-md shadow-lg"
          />
          <h3>Reach your goals</h3>
          <p className="px-4">
            Set clear objectives, identify areas for improvement, and optimize
            your portfolio. TickerZone provides seamless target-setting tools,
            ensuring that each investment has a predetermined outcome in sight.
          </p>
        </div>
      </div>
      <div className="mt-8 flex justify-center">
        <Link
          to="/auth/create-new-account/"
          className="rounded-md bg-flamingo-500 px-3 py-2 text-xs font-medium uppercase text-white"
        >
          Get started
        </Link>
      </div>
    </div>
  )
}
