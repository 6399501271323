import Select from 'react-select';
import { FieldWrapper } from './FieldWrapper';

const customStyles = {
  // Define styles for the dark mode variant of bg-dim-100
  control: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused
      ? 'var(--bg-dim-100)'
      : 'var(--bg-dim-100-dark)',
    borderColor: state.isFocused
      ? 'var(--bg-dim-100)'
      : 'var(--bg-dim-100-dark)',
    boxShadow: state.isFocused
      ? '0 0 0 2px var(--border-color-dim-100)'
      : 'none',
    '&:hover': {
      backgroundColor: 'var(--bg-dim-100-hover)',
    },
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected
      ? state.isDarkMode
        ? '#2d3748'
        : '#f0f4f8'
      : state.isFocused
      ? state.isDarkMode
        ? '#4a5568'
        : '#ebf4ff'
      : state.isDarkMode
      ? '#1a202c'
      : 'white',
    color: state.isDarkMode ? 'white' : 'black',
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: state.isSelected ? (state.isDarkMode ? 'white' : 'black') : '',
  }),
  input: (provided, state) => ({
    ...provided,
    color: state.isSelected ? (state.isDarkMode ? 'white' : 'black') : '',
  }),
};

export const ReactSelectField = props => {
  const {
    label,
    options,
    error,
    setValue,
    name,
    onInputChange,
    required,
    defaultValue,
    onMenuClose,
    isMulti = false,
  } = props;

  const onSelectStateChange = selectState => {
    setValue(name, selectState ? selectState.value : null);
  };

  return (
    <FieldWrapper label={label} error={error}>
      <Select
        className="basic-single"
        classNamePrefix="select"
        styles={customStyles}
        isClearable={true}
        isSearchable={true}
        onMenuClose={onMenuClose}
        name={name}
        required={required}
        isMulti={isMulti}
        onInputChange={onInputChange}
        defaultValue={defaultValue}
        options={options}
        onChange={onSelectStateChange}
        maxMenuHeight={100}
      />
    </FieldWrapper>
  );
};
