// @ts-nocheck
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'components/elements'
import { AuthLayout } from '../components/AuthLayout'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { resetPassword } from '../api/resetPassword'
import { InputFieldV2 } from 'components/form/InputFieldv2'

export const ResetPassword = () => {
  const navigate = useNavigate()
  const [params, _] = useSearchParams()
  const reset_token = params.get('token')
  const [password1, setPassword] = useState('')
  const [password2, setPassword2] = useState('')
  const [passwordError, setPasswordError] = useState('')

  const validatePasswordMatch = () => {
    if (password1 !== password2) {
      setPasswordError('Passwords do not match')
      return false
    } else {
      setPasswordError('')
      return true
    }
  }

  const handlePasswordChange = e => {
    setPassword(e.target.value)
  }

  const handlePassword2Change = e => {
    setPassword2(e.target.value)
  }

  const handlePasswordReset = async () => {
    if (!validatePasswordMatch()) {
      return // Don't proceed if passwords don't match
    }

    if (password1 && reset_token) {
      const password = password1
      const token = reset_token
      resetPassword({ password, token })
    }
    navigate('/auth/reset-password-success')
  }

  return (
    <AuthLayout option="reset-password">
      <div className="">
        <h1 className="my-12 text-4xl font-bold">Reset Password</h1>
        <form className="flex w-full flex-col items-center gap-6">
          <InputFieldV2
            value={password1}
            onChange={handlePasswordChange}
            type="password"
            className="w-full rounded-full border border-solid border-gray-300 bg-gray-50 px-8 py-1.5 outline-none focus:outline-none"
            placeholder="Password"
            maxLength={50}
          />
          <InputFieldV2
            value={password2}
            onChange={handlePassword2Change}
            type="password"
            className="w-full rounded-full border border-solid border-gray-300 bg-gray-50 px-8 py-1.5 outline-none focus:outline-none"
            placeholder="Confirm Password"
            maxLength={50}
          />
          {passwordError && <div className="text-red-500">{passwordError}</div>}
          <Button onClick={handlePasswordReset}>Reset Password</Button>
          <Link
            to="/auth/login"
            className="w-fit rounded-full px-8 py-1.5 outline-none focus:outline-none"
          >
            Back to login
          </Link>
        </form>
      </div>
    </AuthLayout>
  )
}
