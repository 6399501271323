import Select from 'react-select';
import clsx from 'clsx';

export const MultiSelectField = ({
  label,
  options,
  error,
  registration,
  placeholder,
  helperText,
  multiple = true,
  defaultValue,
}) => {
  return (
    <div>
      {label && (
        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
          {label}
        </label>
      )}
      <Select
        isMulti={multiple}
        options={options}
        value={options.filter(option =>
          registration.value?.includes(option.value)
        )}
        onChange={selectedOptions => {
          const values = selectedOptions
            ? selectedOptions.map(option => option.value)
            : [];
          registration.onChange(values);
        }}
        onBlur={registration.onBlur}
        name={registration.name}
        placeholder={placeholder}
        className={clsx('react-select-container', error && 'border-red-500')}
        classNamePrefix="react-select"
        styles={{
          control: (base, state) => ({
            ...base,
            background: 'transparent',
            borderColor: error
              ? '#EF4444'
              : state.isFocused
              ? '#3B82F6'
              : '#E5E7EB',
            '&:hover': {
              borderColor: state.isFocused ? '#3B82F6' : '#D1D5DB',
            },
            boxShadow: state.isFocused ? '0 0 0 1px #3B82F6' : 'none',
            borderRadius: '0.375rem',
          }),
          option: (base, state) => ({
            ...base,
            backgroundColor: state.isSelected
              ? '#3B82F6'
              : state.isFocused
              ? '#EFF6FF'
              : 'transparent',
            color: state.isSelected ? 'white' : '#374151',
            '&:active': {
              backgroundColor: '#3B82F6',
            },
          }),
          multiValue: base => ({
            ...base,
            backgroundColor: '#EFF6FF',
            borderRadius: '0.25rem',
          }),
          multiValueLabel: base => ({
            ...base,
            color: '#3B82F6',
            fontWeight: 500,
          }),
          multiValueRemove: base => ({
            ...base,
            color: '#3B82F6',
            '&:hover': {
              backgroundColor: '#DBEAFE',
              color: '#2563EB',
            },
          }),
        }}
      />
      {helperText && (
        <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
          {helperText}
        </p>
      )}
      {error && <p className="mt-1 text-sm text-red-500">{error.message}</p>}
    </div>
  );
};
