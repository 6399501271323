import { lazy } from 'react';

/**
 *
 * @param factory
 * function that returns a promise e.g () => `import('features/settings')`
 * @param name
 * name of the export of the module e.g `SettingsRoutes`
 * @returns
 */
export const lazyImport = <T extends Record<string, any>>(
  factory: () => Promise<T>,
  name: keyof T
) => lazy(() => factory().then(module => ({ default: module[name] })));
