// @ts-nocheck

import { useAddOptionPosition } from '../api/addOptionPosition';
import { usePortfolios } from '../api/getPortfolios';
import { useTickers } from '../api/getTickers';
import {
  Form,
  FormDrawer,
  InputField,
  ReactSelectField,
} from 'components/form';
import { Button } from 'components/elements';
import { useState, useMemo } from 'react';
import { InputQuantity } from 'components/form/InputQuantity';
import { useEffect } from 'react';
import storage from 'utils/storage';

type AddOptionPositionProps = {
  triggerButton: React.ReactNode;
  ticker?: string;
};

export const AddOptionPosition = ({
  triggerButton,
  ticker,
}: AddOptionPositionProps) => {
  const addOptionPositionMutation = useAddOptionPosition();
  // @ts-ignore
  const portfoliosQuery = usePortfolios({ ticker_type: 'option' });
  const [searchInput, setSearchInput] = useState('');
  const [activeTickerType, setActiveTickerType] = useState('option'); // Default to option since this is for options

  // Get active portfolio when component mounts
  useEffect(() => {
    if (portfoliosQuery.data) {
      const activePortfolioId = storage.getActivePortfolio();
      const activePortfolio = portfoliosQuery.data.find(
        p => p.portfolio_id === activePortfolioId
      );
      if (activePortfolio) {
        setActiveTickerType(activePortfolio.ticker_type);
      }
    }
  }, [portfoliosQuery.data]);

  // Filter portfolios by active ticker type
  const availablePortfolios = useMemo(() => {
    return (
      portfoliosQuery.data?.filter(p => p.ticker_type === activeTickerType) ||
      []
    );
  }, [portfoliosQuery.data, activeTickerType]);

  const tickersQuery = useTickers(searchInput);
  const resultTickers = useMemo(() => {
    if (searchInput.length < 1) {
      return [];
    }
    return tickersQuery.data;
  }, [tickersQuery, searchInput]);

  if (!portfoliosQuery.data) return null;

  return (
    <>
      <FormDrawer
        size="sm"
        isDone={addOptionPositionMutation.isSuccess}
        triggerButton={triggerButton}
        title={`${
          ticker
            ? `Add Option Position for ${ticker}`
            : 'Add New Option Position'
        }`}
        submitButton={
          <Button
            form="add-option-position"
            type="submit"
            size="sm"
            isLoading={addOptionPositionMutation.isLoading}
          >
            Submit
          </Button>
        }
      >
        <Form
          id="add-option-position"
          onSubmit={async values => {
            if (ticker) {
              values.ticker = ticker;
            }
            await addOptionPositionMutation.mutateAsync({ data: values });
          }}
        >
          {({ register, formState, watch, setValue }) => (
            <>
              {ticker ? null : (
                <ReactSelectField
                  label="Search for ticker"
                  editorContent={watch('ticker')}
                  setValue={setValue}
                  name="ticker"
                  required={true}
                  defaultValue={watch('ticker')}
                  error={formState.errors['ticker']}
                  options={
                    Array.isArray(resultTickers)
                      ? resultTickers.map(p => ({
                          label: `${p.ticker} - ${p.company_name}`,
                          value: p.ticker,
                        }))
                      : []
                  }
                  onInputChange={inputValue => setSearchInput(inputValue)}
                />
              )}

              <InputQuantity
                label="Quantity"
                type="number"
                step={1}
                error={formState.errors['quantity']}
                required={true}
                registration={register('quantity', {
                  valueAsNumber: true,
                })}
              />

              <InputField
                label="Strike Price"
                type="number"
                step={0.01}
                required={true}
                error={formState.errors['strike_price']}
                registration={register('strike_price', {
                  valueAsNumber: true,
                })}
              />

              <InputField
                label="Expiration Date"
                type="date"
                required={true}
                error={formState.errors['expiration_date']}
                registration={register('expiration_date')}
              />

              <ReactSelectField
                label="Option Type"
                editorContent={watch('option_type')}
                setValue={setValue}
                name="option_type"
                required={true}
                defaultValue={watch('option_type')}
                error={formState.errors['option_type']}
                options={[
                  { label: 'Call', value: 'call' },
                  { label: 'Put', value: 'put' },
                ]}
              />

              <InputField
                label="Entry Price"
                type="number"
                step={0.01}
                required={true}
                error={formState.errors['entry_price']}
                registration={register('entry_price', {
                  valueAsNumber: true,
                })}
              />

              <InputField
                label="Price Target"
                type="number"
                step={0.01}
                required={true}
                error={formState.errors['price_target']}
                registration={register('price_target', {
                  valueAsNumber: true,
                })}
              />

              <ReactSelectField
                label="Select Portfolio"
                editorContent={watch('portfolio_pk')}
                setValue={setValue}
                name="portfolio_pk"
                required={true}
                defaultValue={watch('portfolio_pk')}
                error={formState.errors['portfolio_pk']}
                options={availablePortfolios.map(p => ({
                  label: p.portfolio_name,
                  value: p.portfolio_id,
                }))}
              />
            </>
          )}
        </Form>
      </FormDrawer>
    </>
  );
};
