// @ts-nocheck
import { useState, useEffect } from 'react';
import { FiBell } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { useNotifications } from '../api/getNotifications';

export const Notification = () => {
  const notificationsQuery = useNotifications();
  const [isBlinking, setIsBlinking] = useState(false);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIsBlinking(prevIsBlinking => !prevIsBlinking);
    }, 500);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  if (notificationsQuery.isLoading) {
    return <></>;
  }

  if (!notificationsQuery.data) {
    return null;
  }

  const countUnreadNotifications = () => {
    if (!notificationsQuery.data) {
      return 0;
    }
    return notificationsQuery.data.filter(not => !not.clicked).length;
  };

  return (
    <Link to="/app/notifications" className="relative hidden md:block">
      {countUnreadNotifications() >= 1 && (
        <div
          className={`absolute top-0 right-0 text-xs bg-red-700 py-.5 px-1 rounded-full text-white text-center
            flex flex-row-reversed ${
              isBlinking
                ? 'animate-blink'
                : '' /* Apply your blinking animation class */
            }`}
        >
          {countUnreadNotifications()}
        </div>
      )}
      <FiBell className="h-8 w-auto text-gray-700 dark:text-white" />
    </Link>
  );
};
