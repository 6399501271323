import { MainLayout } from 'components/layout'
import { useEffect } from 'react'
import { Header } from '../components/Header'

export const TermsOfUse = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])
  return (
    <MainLayout headerContent={<Header start="Terms Of U" tail="se" />}>
      <div className="container mx-auto px-4 py-8">
        <h1 className="mb-4 text-2xl font-bold">
          Thank you for visiting TickerZone.
        </h1>
        <p className="mb-4">
          By accessing or using the TickerZone Website, however accessed, you
          manifest your intent and agree to be bound by this Terms of Use
          Agreement (“Agreement”).
        </p>
        <p className="mb-4">
          If you download and/or purchase Services from the TickerZone Website
          including, but not limited to, the tickerzone software and/or mobile
          application, you agree to be bound by the terms of that Service’s
          End-User License Agreement.
        </p>
        <p className="mb-4">
          If you participate in the tickerzone referral program, you understand
          and agree that you are bound by the tickerzone referral agreement,
          which is hereinafter incorporated by reference.
        </p>
        <p className="mb-4">
          This Agreement and the terms contained within are subject to change by
          TickerZone at any time, in its sole and absolute discretion, and
          without notice, but any change to these terms will not apply
          retroactively. You are under a duty to review the terms of this
          Agreement prior to using the Website. If you do not agree to the terms
          and conditions contained within this Agreement, you must discontinue
          your use of the Website immediately. Your continued use of the Website
          after a replacement, modification, or amendment of the terms of this
          Agreement will constitute your manifestation of assent to, and
          agreement with, any replacement, modification, or amendment herein.
        </p>
        <p className="mb-4">
          TickerZone hereby incorporates its Privacy Policy as if fully restated
          herein. You must review TickerZone’s Privacy Policy to understand what
          personal and personally identifiable information that TickerZone may
          collect from you when you use the Website and how TickerZone may use
          that personal or personally identifiable information.
        </p>
        <hr className="my-8" />

        <p className="mb-4">
          NOTICE OF ARBITRATION. THIS TERMS OF USE AGREEMENT CONTAINS AN
          ARBITRATION PROVISION AND CLASS ACTION WAIVER THAT APPLIES TO ALL
          CLAIMS EXCEPT FOR CLAIMS OF INTELLECTUAL PROPERTY INFRINGEMENT OR
          THOSE THAT CAN BE BROUGHT IN SMALL CLAIMS COURT. EXCEPT AS OTHERWISE
          STATED UNDER THE TERMS OF THIS AGREEMENT, AND IF YOU DO NOT OPT-OUT OF
          ARBITRATION AS SET FORTH BELOW, YOU AGREE THAT ANY AND ALL DISPUTES
          BETWEEN YOU AND TickerZone WILL BE RESOLVED BY BINDING, INDIVIDUAL
          ARBITRATION, AND YOU WAIVE YOUR RIGHT TO BRING OR RESOLVE ANY DISPUTE
          AS, OR PARTICIPATE IN, A CLASS, CONSOLIDATED, REPRESENTATIVE,
          COLLECTIVE, OR PRIVATE ATTORNEY GENERAL ACTION OR ARBITRATION.
        </p>
        <p className="mb-4">
          User Eligibility. The Website is open to use to those who are age
          eighteen (18) or above. By using the Website, you warrant that you are
          age eighteen (18) or above, are of sound mind, and have the capacity
          to agree to and uphold the terms and conditions contained within this
          Agreement. If you use the Website on behalf of a business entity or
          other third party, you warrant that you express actual authority to
          act as an agent of that business entity and third party and, as a
          component of that agency, have the right and ability to agree to the
          terms of this Agreement on behalf of that third party or business
          entity. You further warrant that you are not prohibited from entering
          into this Agreement by the terms of any pre-existing agreement.
        </p>
        <p className="mb-4">
          Intellectual Property Rights. The Website and its associated
          trademarks and content are owned and used by TickerZone, including,
          but not limited to, the tickerzone trademark. Any use of the Website,
          its associated trademarks, or its content without the prior written
          approval of TickerZone is prohibited. The selection, arrangement, and
          layout of the Website is the sole and exclusive property of
          TickerZone, and any unauthorized copying, public display, public
          performance, distribution, or derivation, whether in whole or in part,
          is expressly prohibited.
        </p>
        <p className="mb-4">
          Acceptable Use Policy. When you use the Website, you agree to use it
          only for its customary and intended purposes and as permitted by the
          terms of this Agreement and any applicable law, regulation, statute,
          or ordinance. Additionally, you agree that you are responsible for any
          breach of your obligations under the terms of this Agreement and for
          any losses suffered by TickerZone for such a breach, including, but
          not limited to, monetary damages, costs, and attorneys’ fees.
        </p>
        <ul className="mb-4 ml-8 list-disc">
          <li>
            Attempting to access the Website other than through a standard web
            browser unless you have been permitted to do so by TickerZone
            through a separate, written agreement (this restriction does not
            apply to search engines that comply with TickerZone’s robots.txt
            file);
          </li>
          <li>
            Accessing or attempting to access the Website through automated
            means (this restriction does not apply to search engines that comply
            with TickerZone’s robots.txt file);
          </li>
          <li>
            Circumventing the technological protection measures of the Website;
          </li>
          <li>
            Posting or transmitting content intended to collect personal or
            personally identifiable information from users of the Website or
            third parties;
          </li>
          <li>
            Disrupting or otherwise interfering with the Website or its
            associated servers or networks;
          </li>
          <li>
            Scraping, reproducing, republishing, selling, reselling,
            duplicating, or trading the Website or its content;
          </li>
          <li>
            Diverting or attempting to divert customers of the Website to
            another website or service;
          </li>
          <li>
            Sending unsolicited or unauthorized communications to users of the
            Website or third parties;
          </li>
          <li>
            Transmitting content to the Website that is false, inaccurate,
            misleading, tortious, defamatory, vulgar, obscene, libelous,
            invasive, hateful, or otherwise objectionable;
          </li>
          <li>
            Reverse engineering, decompiling, translating, or disassembling the
            Website or its content; and
          </li>
          <li>
            Encouraging or assisting any other party to do anything in violation
            of the terms of this Agreement.
          </li>
        </ul>
        <p className="mb-4">
          TickerZone reserves the right to modify, amend, or terminate the
          Website or its associated content at any time and without prior
          notice. TickerZone also reserves the right to refuse service or access
          to the Website to any person or business entity at any time and
          without notice.
        </p>
        <p className="mb-4">
          No Professional Advice. TickerZone is not a financial institution,
          exchange, money services business, or creditor. TickerZone is not an
          “investment adviser” as defined in the federal Investment Advisers Act
          of 1940. TickerZone is not a “broker” or a “dealer” as those terms are
          defined under the federal Securities Exchange Act of 1934. TickerZone
          is not a financial planning advisory service of any kind. TickerZone
          provides a desktop and mobile application that allows users of said
          application to control and analyze their own financial information and
          positions with or without the assistance of third-party financial
          advisors. TickerZone does not provide users with tax, legal, financial
          planning, insurance, accounting, investment, or any other kind of
          professional advice or services, and nothing found within, provided
          by, or related to the application and associated services or the
          TickerZone Website should be construed as an offer to sell, a
          solicitation of an offer to buy, or a recommendation for any purchase
          decision, security, insurance policy, or investment strategy. Nothing
          provided by TickerZone within its application and associated services
          or the TickerZone Website should be used as a substitute for the
          individually tailored advice that can be provided by a licensed
          professional. You agree to be solely responsible for your use of the
          application and associated services and the TickerZone Website and any
          information available therein.
        </p>
        <p className="mb-4">
          Third-Party Websites. You acknowledge and agree that the Website may
          contain links to third-party websites or content that TickerZone does
          not own or control. You are advised to review the terms and conditions
          of any third-party websites or content linked to through the Website,
          and you agree that TickerZone will not be responsible for websites not
          under the ownership or control of TickerZone.
        </p>
        <p className="mb-4">
          Term and Termination. The term of this Agreement will begin upon your
          first accessing of the Website and will continue until the earlier of
          the following: (i) TickerZone terminates your access to the Website;
          or (ii) you cease using the Website. TickerZone reserves the right to
          terminate the Website or your access to the Website in its sole and
          absolute discretion and without prior notice.
        </p>
        <p className="mb-4">
          Disclaimer of Warranties and Limitation of Liability.
        </p>
        <p className="mb-4">
          YOU ACKNOWLEDGE AND AGREE THAT THE WEBSITE IS PROVIDED ON AN “AS-IS''
          BASIS AND WITHOUT WARRANTY OF ANY KIND, INCLUDING, BUT NOT LIMITED TO,
          WARRANTIES OF TITLE, MERCHANTABILITY, ACCURACY, FITNESS FOR A
          PARTICULAR PURPOSE, SECURITY, AND NON-INFRINGEMENT. WHEREVER PERMITTED
          BY LAW, YOU ACKNOWLEDGE THAT TickerZone WILL NOT BE HELD RESPONSIBLE
          OR LIABLE FOR ANY CLAIMS, DAMAGES, JUDGMENTS, CHARGES, OR FEES ARISING
          OUT OF OR RELATED TO YOUR USE OF OR ACCESS TO THE WEBSITE, INCLUDING,
          BUT NOT LIMITED TO, COMPENSATORY DAMAGES, CONSEQUENTIAL DAMAGES,
          SPECIAL DAMAGES, INCIDENTAL DAMAGES, PUNITIVE DAMAGES, EXEMPLARY
          DAMAGES, COSTS AND ATTORNEYS’ FEES, DAMAGES ARISING OUT OF ERRORS OR
          OMISSIONS, AND DAMAGES ARISING OUT OF THE UNAVAILABILITY OF THE
          WEBSITE OR DOWNTIME. YOU ACKNOWLEDGE THAT YOUR USE OF THE WEBSITE IS
          AT YOUR SOLE RISK AND THAT TickerZone’S LIABILITY IS LIMITED TO THE
          AMOUNT THAT YOU PAID TO USE THE WEBSITE OR $500, WHICHEVER IS LESS.
        </p>
        <p className="mb-4">
          TickerZone EXPRESSLY DISCLAIMS ANY RESPONSIBILITY FOR ANY DAMAGE,
          INJURY, HARM, COST, EXPENSE, OR LIABILITY ARISING OUT OF OR RELATED TO
          YOUR USE OR MISUSE OF PRODUCTS OR SERVICES PURCHASED THROUGH THE
          WEBSITE. PRODUCTS OR SERVICES PURCHASED THROUGH THE WEBSITE ARE
          PROVIDED ON AN “AS-IS'' BASIS AND WITHOUT WARRANTY OF ANY KIND,
          WHETHER EXPRESS, IMPLIED, OR STATUTORY, INCLUDING, BUT NOT LIMITED TO,
          WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
          TITLE, ACCURACY, NON-INFRINGEMENT, OR QUALITY. SOME JURISDICTIONS DO
          NOT ALLOW AN EXCLUSION OF IMPLIED WARRANTIES. IF YOU ARE LOCATED IN
          SUCH A JURISDICTION, YOU ARE ADVISED TO SEEK LEGAL ADVICE TO DETERMINE
          IF THIS EXCLUSION APPLIES TO YOU.
        </p>
        <p className="mb-4">
          TickerZone WILL NOT BE LIABLE TO YOU UNDER ANY LEGAL THEORY, WHETHER
          IN WARRANTY, CONTRACT, STRICT LIABILITY, TORT, OR NEGLIGENCE, FOR ANY
          DAMAGES, CLAIMS, INJURIES, JUDGMENTS, COSTS, OR LIABILITIES OF ANY
          KIND ARISING OUT OF OR RELATED TO YOUR USE OR MISUSE OF THE WEBSITE OR
          PRODUCTS OR SERVICES PURCHASED THROUGH THE WEBSITE, INCLUDING, BUT NOT
          LIMITED TO, PERSONAL INJURY, ILLNESS, DAMAGE TO PROPERTY, LOSS OF
          BUSINESS, LOSS OF INCOME, SPECIAL DAMAGES, INCIDENTAL DAMAGES,
          CONSEQUENTIAL DAMAGES, PUNITIVE DAMAGES, OR EXEMPLARY DAMAGES, WHETHER
          FORESEEABLE OR UNFORESEEABLE.
        </p>
        <p className="mb-4">
          Indemnification. You agree to indemnify, defend, and hold harmless
          TickerZone, its officers, shareholders, directors, employees,
          subsidiaries, affiliates, and representatives from any and all losses,
          including, but not limited to, costs and attorneys’ fees, arising out
          of or related to (i) your use of the Website, (ii) your purchase of
          products or services through the Website; (iii) your use or misuse of
          products or services obtained through the Website; (iv) your violation
          of any term or condition of this Agreement; (v) your violation of the
          rights of third parties, including, but not limited to, intellectual
          property rights or other personal or proprietary rights; and (vi) your
          violation of any law, statute, ordinance, regulation, or treaty,
          whether local, state, provincial, national, or international. Your
          obligation to defend TickerZone will not provide you with the ability
          to control TickerZone’s defense, and TickerZone reserves the right to
          control its defense, including its choice of counsel and whether to
          litigate or settle a claim subject to indemnification.
        </p>
        <p className="mb-4">Choice of Law and Stipulation to Jurisdiction.</p>
        <p className="mb-4">
          You and TickerZone agree that any dispute arising out of or related to
          this Agreement or your use of the Website will be governed by the laws
          of the State of Nevada, without regard to its conflict of laws rules.
          Specifically, the validity, interpretation, and performance of this
          Agreement will not be governed by the United Nations Convention on the
          International Sale of Goods. Except for claims that may be brought in
          small claims court, claims of intellectual property infringement, or
          claims for injunctive relief by either party, you and TickerZone agree
          that any dispute or controversy arising out of, in relation to, or in
          connection with this Agreement including, without limitation, any and
          all disputes, claims (whether in tort, contract, statutory, or
          otherwise), or disagreements concerning the existence, breach,
          interpretation, application, or termination of this Agreement, will be
          resolved by final and binding arbitration pursuant to the Federal
          Arbitration Act in Las Vegas, Nevada or, at the option of the party
          seeking relief, by telephone, online, or via written submissions
          alone, and be administered by the American Arbitration Association
          (“AAA”) under the then in force Commercial Arbitration Rules by one
          arbitrator appointed in accordance with such rules. Such arbitration
          will be independent and impartial. If the parties fail to agree on the
          arbitrator within twenty (20) calendar days after the initiation of
          the arbitration hereunder, AAA will appoint the arbitrator.
        </p>
        <p className="mb-4">
          This arbitration will be conducted in the English language. The
          decision of the arbitrator will be final and binding on the parties
          and judgment on any award(s) rendered by the arbitrator may be entered
          in any court having jurisdiction thereof. Nothing in this section will
          prevent either party from seeking immediate injunctive relief from any
          court of competent jurisdiction, and any such request shall not be
          deemed incompatible with the agreement to arbitrate or a waiver of the
          right to arbitrate. The parties undertake to keep confidential all
          awards in their arbitration, together with all confidential
          information, all materials in the proceedings created for the purpose
          of the arbitration and all other documents produced by the other party
          in the proceedings and not otherwise in the public domain, save and to
          the extent that disclosure may be required of a party by legal duty,
          to protect or pursue a legal right or to enforce or challenge an award
          in legal proceedings before a court or other judicial authority. The
          arbitrator shall award all fees and expenses, including reasonable
          attorney’s fees, to the prevailing party. Any judgment rendered by the
          arbitrator may be entered in any court of competent jurisdiction.
        </p>
        <p className="mb-4">
          ANY DISPUTE RESOLUTION PROCEEDING ARISING OUT OF OR RELATED TO THIS
          AGREEMENT, WHETHER IN ARBITRATION OR OTHERWISE, SHALL BE CONDUCTED
          ONLY ON AN INDIVIDUAL BASIS AND NOT IN A CLASS, CONSOLIDATED OR
          REPRESENTATIVE ACTION, AND YOU AND TickerZone EXPRESSLY AGREE THAT
          CLASS ACTION AND REPRESENTATIVE ACTION PROCEDURES SHALL NOT BE
          ASSERTED IN NOR APPLY TO ANY ARBITRATION PURSUANT TO THESE TERMS.
        </p>
        <p className="mb-4">
          Any claims must be brought within one year of accrual or will
          otherwise be barred.
        </p>
        <p className="mb-4">
          Force Majeure. TickerZone will not be responsible for any delay or
          failure in performance of the Website or its associated products or
          services arising out of any cause beyond TickerZone’s control, such as
          acts of God, war, riots, fire, terrorist attacks, pandemics, power
          outages, severe weather, or other accidents.
        </p>
        <p className="mb-4">
          Survivability. The representations, warranties, duties, and covenants
          made by you under this Agreement will survive the termination of this
          Agreement or the Website including, but not limited to, your duty to
          indemnify and defend TickerZone.
        </p>
        <p className="mb-4">
          Interpretation. This Agreement will be deemed to have been drafted by
          both parties, and the terms and conditions of this Agreement will not
          be interpreted against its drafter.
        </p>
        <p className="mb-4">
          Assignment. You are expressly prohibited from assigning your rights
          and duties under this Agreement. TickerZone reserves the right to
          assign its rights and duties under this Agreement, including in a sale
          of TickerZone or its Website.
        </p>
        <p className="mb-4">
          Waiver and Integration. No term or condition of this Agreement or
          breach of this Agreement will be deemed to have been waived or
          consented to unless said waiver is written and signed by the party to
          be charged. This Agreement is the entire agreement between the parties
          and supersedes all previous agreements or representations between the
          parties other than conflicting terms found in the TickerZone End-User
          License Agreement, where applicable.
        </p>
      </div>
    </MainLayout>
  )
}
