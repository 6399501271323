// @ts-nocheck
import { useEffect } from 'react';
import { FullScreenLoader } from 'components/elements';
import {
  loginWithEmailAndPassword,
  getUser,
  resetPassword,
  registerWithEmailAndPassword,
} from 'features/auth';
import storage from 'utils/storage';
import { initReactQueryAuth } from 'react-query-auth';
import { getPortfolios } from 'features/portfolios';
import { useQueryClient } from 'react-query'; // React Query Client for cache invalidation

async function handleUserResponse(data: any) {
  const { access, refresh, user } = data;
  storage.setToken(access);
  storage.setRefreshToken(refresh);
  return user; // Return user data
}

async function loadUser() {
  const currentToken = storage.getToken();

  if (currentToken) {
    try {
      const data = await getUser();
      return data; // Return user object if available
    } catch (error) {
      // Handle token expiration or invalid token
      storage.clearToken();
      return null;
    }
  }
  return null; // No user if no token is found
}

async function loginFn(data: any) {
  const response = await loginWithEmailAndPassword(data);
  const user = await handleUserResponse(response);

  if (user) {
    const portfolios = await getPortfolios();
    if (!portfolios.length) {
      window.location.assign('/on-boarding/portfolio/create');
    } else {
      portfolios.sort((a, b) => b.portfolio_id - a.portfolio_id);
      storage.setActivePortfolio(portfolios[0]?.portfolio_id);
    }
  }
  return user;
}

async function passwordresetFn(data: any) {
  const response = await resetPassword(data);
  const user = await handleUserResponse(response);
  return user;
}

async function registerFn(data: any) {
  const response = await registerWithEmailAndPassword(data);
  const user = await handleUserResponse(response);
  return user;
}

async function logoutFn() {
  storage.clearToken(); // Clear tokens on logout
}

const authConfig = {
  loadUser,
  loginFn,
  passwordresetFn,
  registerFn,
  logoutFn,
  LoaderComponent() {
    return <FullScreenLoader />;
  },
};

// Initialize authentication context with `initReactQueryAuth`
export const { AuthProvider, useAuth: useOriginalAuth } =
  initReactQueryAuth(authConfig);

// Custom `useAuth` hook to include `isAuthenticated` and `isLoading` fields
export const useAuth = () => {
  const auth = useOriginalAuth();
  const queryClient = useQueryClient();

  const isAuthenticated = !!auth.user;
  const isLoading =
    auth.status === 'loading' ||
    auth.isLoading === true ||
    auth.user === undefined;

  // Invalidate and refetch user queries upon authentication to trigger re-render
  useEffect(() => {
    if (isAuthenticated) {
      queryClient.invalidateQueries('user'); // Refetch user data if needed
    }
  }, [isAuthenticated, queryClient]);

  return {
    ...auth,
    isAuthenticated,
    isLoading, // Ensure loading state is exposed
  };
};
