import { Navigate, Route, Routes } from 'react-router-dom';
import { Profile } from './Profile';

export const AccountRoutes = () => {
  return (
    <Routes>
      <Route path="profile" element={<Profile />} />
      <Route path="*" element={<Navigate to="." />} />
    </Routes>
  );
};
