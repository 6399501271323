import clsx from 'clsx';
import { ArchiveBoxIcon } from '@heroicons/react/24/outline';
import { JSX, useEffect, useState } from 'react';
import { Pagination } from '../pagination';

interface Column {
  title: string;
  field?: string;
  Cell?: (props: { entry: any }) => JSX.Element;
}

interface TableProps {
  data: any[];
  columns: Column[];
}

export const Table = ({ data, columns }: TableProps) => {
  const [posts, setPosts] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(10);

  useEffect(() => {
    setPosts(data);
  }, [data]);

  // Get current posts
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);

  const pageNumberLimit = 5;
  const [maxPageNumber, setmaxPageNumber] = useState(5);
  const [minPageNumber, setminPageNumber] = useState(1);

  // Change page
  // const paginateFront = () => setCurrentPage(currentPage + 1);
  // const paginateBack = () => setCurrentPage(currentPage - 1);

  const paginateFront = () => {
    setCurrentPage(currentPage + 1);
    if (currentPage + 1 > maxPageNumber) {
      setmaxPageNumber(maxPageNumber + pageNumberLimit);
      setminPageNumber(minPageNumber + pageNumberLimit);
    }
  };
  const paginateBack = () => {
    setCurrentPage(currentPage - 1);
    if ((currentPage - 1) % pageNumberLimit === 0) {
      setmaxPageNumber(maxPageNumber - pageNumberLimit);
      setminPageNumber(minPageNumber - pageNumberLimit);
    }
  };

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  if (!posts?.length) {
    return (
      <div className="bg-white dark:bg-dim-300 text-gray-500 h-40 md:h-80 flex justify-center items-center flex-col">
        <ArchiveBoxIcon className="h-16 w-16 text-gray-100" />
        <h4 className="text-gray-500">You have no entries at the moment</h4>
      </div>
    );
  }

  // Helper functions to determine column type
  const isTextColumn = (column: Column): boolean => {
    const textColumns = ['Ticker', 'Type', 'Status'];
    return textColumns.includes(column.title);
  };

  const isNumericColumn = (column: Column): boolean => {
    const numericColumns = [
      'Price',
      'Strike',
      'Target',
      'Delta',
      'Theta',
      'IV',
      'Contracts',
      'Gain',
    ];
    return numericColumns.includes(column.title);
  };

  const isCenterColumn = (column: Column): boolean => {
    const centerColumns = ['Chart', 'Target Move', '']; // Empty string for action columns
    return centerColumns.includes(column.title);
  };

  return (
    <div className="flex flex-col">
      <div className="-my-2 sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="table-container">
            <table className="w-full text-sm text-left text-gray-500 rounded-header">
              <thead className="bg-[#8C8CA1] dark:bg-dim-200">
                <tr>
                  {columns.map((column: Column, index: number) => (
                    <th
                      key={column.title + index}
                      scope="col"
                      className={clsx(
                        'px-6 py-4 whitespace-nowrap text-xs font-medium text-gray-100',
                        // Text alignment based on content type
                        {
                          'text-left': isTextColumn(column), // For text/string data
                          'text-right': isNumericColumn(column), // For numbers/prices
                          'text-center': isCenterColumn(column), // For status/icons/charts
                        }
                      )}
                    >
                      {/* Convert column titles to Title Case, except for special cases */}
                      {column.title === column.title.toUpperCase()
                        ? column.title // Keep acronyms (like 'IV') as is
                        : column.title
                            .split(' ')
                            .map((word: string) =>
                              // Keep words like 'of', 'in', 'to' lowercase
                              ['of', 'in', 'to', 'for', 'and', 'or'].includes(
                                word.toLowerCase()
                              )
                                ? word.toLowerCase()
                                : word.charAt(0).toUpperCase() +
                                  word.slice(1).toLowerCase()
                            )
                            .join(' ')}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {currentPosts.map((entry: any, index: number) => {
                  return (
                    <tr
                      key={index}
                      className="bg-white dark:bg-dim-300 dark:hover:bg-dim-100 border-b border-gray-100 
                      hover:bg-gray-100 rounded-lg"
                    >
                      {columns.map(
                        ({ Cell, field, title }: Column, index: number) => (
                          <td
                            key={index}
                            className="px-4 py-4 whitespace-nowrap text-xs  font-medium text-gray-600
                          dark:text-gray-400"
                          >
                            {Cell ? (
                              <Cell entry={entry} />
                            ) : (
                              entry[field as string]
                            )}
                          </td>
                        )
                      )}
                    </tr>
                  );
                })}
                {!currentPosts?.length ? (
                  <tr className="bg-white dark:bg-dim-300 border-b">
                    <td colSpan={columns.length}>
                      <div
                        className="bg-gray-100 dark:bg-dim-300 text-gray-600 row py-2 rounded-md 
                      flex justify-center"
                      >
                        <h4 className="text-gray-600">
                          You have no more entries
                        </h4>
                      </div>
                    </td>
                  </tr>
                ) : null}
              </tbody>
            </table>
          </div>
          <div className="flex justify-end mt-16 my-4">
            <Pagination
              postsPerPage={postsPerPage}
              totalPosts={posts.length}
              paginate={paginate}
              currentPage={currentPage}
              paginateFront={paginateFront}
              paginateBack={paginateBack}
              maxPageNumber={maxPageNumber}
              minPageNumber={minPageNumber}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
