import { Header } from './components/Header';
import { Footer } from './components/Footer';
import { useColorModeContext } from 'context/ColorContext';
interface MainLayoutProps {
  children: React.ReactNode;
  headerHeight?: number;
  headerContent: React.ReactNode;
}
export const MainLayout = ({
  children,
  headerHeight,
  headerContent,
}: MainLayoutProps) => {
  const darkMode = useColorModeContext();

  return (
    <div className={darkMode[0] ? `dark` : ``}>
      <div className="relative">
        {/* Background illustration */}
        <div className="z-0 flex h-[36rem] w-full md:h-[42rem] lg:h-[46rem]">
          <div className="flex h-full w-3/4 flex-col justify-between">
            <div className="mx-auto h-[2rem] w-[4rem] rounded-b-full bg-[#FE5F00] opacity-[.25] lg:h-[6rem] lg:w-[12rem]"></div>
            <div className="-ml-24 h-[10rem] w-[10rem] rounded-full bg-[#FE5F00] opacity-[.25] lg:h-[20rem] lg:w-[20rem]"></div>
          </div>
          <div className="h-full w-1/4 items-center">
            <div className="absolute right-0 h-[24rem] w-[16rem] overflow-hidden rounded-l-full bg-[#FE5F00] opacity-[.25] lg:h-[32rem]"></div>
          </div>
        </div>
        <div className="z-1 absolute top-0 w-full">
          <Header height={headerHeight} children={headerContent} />
          <div className="dark:bg-dim-300 dark:text-white">{children}</div>
          <Footer />
        </div>
      </div>
    </div>
  );
};
