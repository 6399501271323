// @ts-nocheck
import { ContentLayout } from 'components/layout';
import FreePikStoriesLeader from 'assets/freepik_stories-leader.svg';
import { Button } from 'components/elements';
import { useNavigate } from 'react-router-dom';

export const CreatePortfolioSuccess = () => {
  const navigate = useNavigate();

  const handleCreatePortfolio = async () => {
    navigate('/app/portfolios');
  };

  return (
    <ContentLayout>
      <div className="flex flex-col text-center justify-center items-center space-y-2">
        <img src={FreePikStoriesLeader} alt="Success" className="h-48" />
        <h3>Awesome!</h3>
        <p>You’ve successfully created a new portfolio.</p>
        <Button
          onClick={handleCreatePortfolio}
          className="w-64 text-xs py-4 whitespace-nowrap"
        >
          Go to Portfolio Holdings
        </Button>
      </div>
    </ContentLayout>
  );
};
