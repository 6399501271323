// @ts-nocheck
import { axios } from 'lib/axios'
import { queryClient } from 'lib/react-query'
import { useNotificationStore } from 'stores/notifications'
import { useMutation } from 'react-query'

export const registerWithEmailAndPassword = data => {
  return axios.post('/auth/register/', data)
}

export const useRegisterWithEmailAndPassword = ({ config = {} } = {}) => {
  const { addNotification } = useNotificationStore()

  return useMutation({
    onMutate: async newAccount => {
      // You can perform actions before the mutation starts, e.g., show a loading indicator
    },
    onError: (error, variables, context) => {
      // Handle the mutation error, e.g., show an error message
      console.log(error)
      addNotification({
        type: 'error',
        message: 'Registration failed',
      })
    },
    onSuccess: (data, variables, context) => {
      // Handle the mutation success, e.g., redirect to a new page
      // You can also update the query data if needed
      queryClient.invalidateQueries('NewAccount') // Replace 'someQueryKey' with the actual query key you want to invalidate
    },
    ...config,
    mutationFn: registerWithEmailAndPassword, // Set the mutation function to the correct function
  })
}
