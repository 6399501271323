export const Header = ({ start, tail }: { start: string; tail: string }) => {
  return (
    <div className="mx-8 py-12 text-3xl font-bold text-white md:mx-28 md:text-8xl">
      {start}
      <u className="underline decoration-[#FE5F00] decoration-4 underline-offset-[15px]">
        {tail}.
      </u>
    </div>
  )
}
