import React from 'react';
import { HomeNavbar } from './HomeNavbar';

export const Header = ({
  height,
  children,
}: {
  height: number;
  children: React.ReactNode;
}) => {
  return (
    <div className="relative">
      <div className={`bg-[#0C0C0E] opacity-[.84] h-${height}`}>
        <HomeNavbar />
        <div className="">{children}</div>
      </div>
    </div>
  );
};
