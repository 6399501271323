// @ts-nocheck
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { FiPlus, FiSettings, FiLock } from 'react-icons/fi';
import { Search } from './Search';
import clsx from 'clsx';
import profile from 'assets/profile.png';
import { Menu } from '@headlessui/react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from 'lib/auth';
import { useAccountDetail } from 'features/account';
import { FullScreenLoader } from 'components/elements';
import { Notification } from './Notification';

export const AppNavBar = ({ sidebarOpen, setSidebarOpen }) => {
  const { logout } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  // Determine current page
  const isHoldingsPage = location.pathname.includes('/portfolios');
  const isWatchlistPage = location.pathname.includes('/watchlist');
  const isTradeLogsPage = location.pathname.includes('/trades/logs');

  const handleCreatePortfolio = async () => {
    setIsLoading(true);
    try {
      await navigate('/app/portfolios/create');
    } finally {
      setIsLoading(false);
    }
  };

  const accountDetailsQuery = useAccountDetail();

  if (accountDetailsQuery.isLoading) {
    return <FullScreenLoader />;
  }

  if (!accountDetailsQuery.data) return null;

  const { name, email } = accountDetailsQuery.data;

  return (
    <div
      className={clsx(
        'sticky top-0 z-10',
        'bg-white/[.9] dark:bg-dim-300/[.9]',
        'backdrop-blur-sm',
        'border-b border-gray-200 dark:border-gray-700'
      )}
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center h-16">
          {/* Left side */}
          <div className="flex items-center space-x-4">
            {/* Show Search for Holdings and Watchlist pages */}
            {(isHoldingsPage || isWatchlistPage) && <Search className="w-64" />}
          </div>

          {/* Right side */}
          <div className="flex items-center space-x-4">
            {/* Create Portfolio Button - Only show on Holdings page */}
            {isHoldingsPage && (
              <button
                onClick={handleCreatePortfolio}
                disabled={isLoading}
                className={clsx(
                  'hidden md:inline-flex items-center px-5 py-2.5',
                  'rounded-lg transition-all duration-200 ease-in-out',
                  'text-sm font-semibold shadow-sm',
                  isLoading
                    ? 'bg-gray-200 text-gray-400 cursor-not-allowed dark:bg-gray-700 dark:text-gray-500'
                    : clsx(
                        'bg-gray-50 dark:bg-gray-800', // Lighter/darker backgrounds
                        'border border-gray-200 dark:border-gray-700', // Subtle borders
                        'text-gray-900 dark:text-gray-100',
                        'dark:focus:ring-blue-500/40 dark:focus:border-blue-500',
                        'transform hover:-translate-y-0.5 active:translate-y-0'
                      ),
                  'focus:outline-none focus:ring-2 focus:ring-offset-2',
                  'focus:ring-[#3B82F6] dark:focus:ring-[#1E40AF] dark:focus:ring-offset-gray-800'
                )}
              >
                {isLoading ? (
                  <span className="flex items-center">
                    <svg
                      className="animate-spin -ml-1 mr-2 h-4 w-4 text-gray-500"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      />
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      />
                    </svg>
                    <span className="text-gray-500">Creating...</span>
                  </span>
                ) : (
                  <span className="flex items-center">
                    <FiPlus className="h-4 w-4 mr-2 text-gray-900 dark:text-gray-100" />
                    <span>New Portfolio</span>
                  </span>
                )}
              </button>
            )}

            {/* Notification Bell */}
            <Notification />

            {/* Profile Dropdown */}
            <Menu as="div" className="relative">
              <Menu.Button className="flex items-center space-x-3 p-2 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700">
                <img
                  src={profile}
                  alt="Profile"
                  className="h-8 w-8 rounded-full ring-2 ring-white dark:ring-gray-700"
                />
              </Menu.Button>

              <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right rounded-lg bg-white dark:bg-gray-800 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <Link
                        to="/app/account_settings"
                        className={clsx(
                          'flex items-center px-4 py-2 text-sm',
                          active
                            ? 'bg-gray-100 dark:bg-gray-700 text-gray-900 dark:text-gray-100'
                            : 'text-gray-700 dark:text-gray-300'
                        )}
                      >
                        <FiSettings className="mr-3 h-4 w-4" />
                        Settings
                      </Link>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={() => {
                          navigate('/');
                          logout();
                        }}
                        className={clsx(
                          'flex w-full items-center px-4 py-2 text-sm',
                          active
                            ? 'bg-gray-100 dark:bg-gray-700 text-red-600 dark:text-red-400'
                            : 'text-red-500 dark:text-red-400'
                        )}
                      >
                        <FiLock className="mr-3 h-4 w-4" />
                        Sign out
                      </button>
                    )}
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Menu>
          </div>
        </div>
      </div>
    </div>
  );
};
