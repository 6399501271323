import { Spinner } from 'components/elements';
import clsx from 'clsx';
import * as React from 'react';

// const variants = {
//   primary:
//     'border-none bg-flamingo-500 dark:bg-flamingo-500 text-white hover:bg-flamingo-500 hover:text-white',
//   inverse:
//     'border-none bg-white text-flamingo-600 dark:text-white dark:bg-flamingo-700',
//   danger: 'border-none bg-red-600 text-white hover:bg-red-50:text-red-600',
//   outline:
//     'bg-white dark:bg-dim-100 border text-flamingo-500 border-flamingo-500',
//   darkoutline:
//     'bg-white dark:bg-dim-100 dark:text-gray-100 border text-gray-500 border-gray-500',
// };

const variants = {
  primary: 'bg-blue-600 text-white hover:bg-blue-700',
  secondary: 'bg-white text-gray-700 border-gray-300 hover:bg-gray-50',
  danger: 'bg-red-600 text-white hover:bg-red-700',
  warning: 'bg-yellow-600 text-white hover:bg-yellow-700',
  info: 'bg-blue-500 text-white hover:bg-blue-600',
  inverse:
    'border-none bg-white text-flamingo-600 dark:text-white dark:bg-flamingo-700',
  outline:
    'bg-white dark:bg-dim-100 border text-flamingo-500 border-flamingo-500',
  darkoutline:
    'bg-white dark:bg-dim-100 dark:text-gray-100 border text-gray-500 border-gray-500',
} as const;

const sizes = {
  xs: 'py-1 px-2 text-xs',
  sm: 'py-2 px-4 text-sm',
  md: 'py-2 px-6 text-md',
  lg: 'py-3 px-8 text-lg',
} as const;

type ButtonProps = {
  type?: 'button' | 'submit' | 'reset';
  className?: string;
  variant?: keyof typeof variants;
  size?: keyof typeof sizes;
  isLoading?: boolean;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  children: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
} & Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'type' | 'className' | 'children' | 'onClick' | 'disabled'>;

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      type = 'button',
      className = '',
      variant = 'primary',
      size = 'md',
      isLoading = false,
      startIcon,
      endIcon,
      children,
      ...props
    },
    ref
  ) => {
    return (
      <button
        ref={ref}
        type={type}
        className={clsx(
          'flex justify-center capitalize items-center rounded-md shadow-sm font-medium focus:outline-none',
          variants[variant],
          sizes[size],
          className
        )}
        disabled={isLoading}
        {...props}
      >
        {isLoading && <Spinner size="sm" className="text-current" />}
        {!isLoading && startIcon}
        <span className="mx-2">{children}</span> {!isLoading && endIcon}
      </button>
    );
  }
);

Button.displayName = 'Button';
