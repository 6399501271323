import { Button } from 'components/elements/button';
import { Dialog, DialogTitle } from 'components/elements/dialog';
import { useDisclosure } from 'hooks/useDisclosure';
import {
  ExclamationTriangleIcon as ExclamationIcon,
  InformationCircleIcon,
} from '@heroicons/react/24/outline';
import * as React from 'react';
import { useColorModeContext } from 'context/ColorContext';

export const ConfirmationDialog = ({
  triggerButton,
  confirmButton,
  title,
  body = '',
  cancelButtonText = 'Cancel',
  icon = 'danger',
  isDone = false,
}: {
  triggerButton: React.ReactNode;
  confirmButton: React.ReactNode;
  title: string;
  body?: string;
  cancelButtonText?: string;
  icon?: string;
  isDone?: boolean;
}) => {
  const { close, open, isOpen } = useDisclosure();

  const cancelButtonRef = React.useRef(null);

  React.useEffect(() => {
    if (isDone) {
      close();
    }
  }, [isDone, close]);
  // @ts-ignore
  const trigger = React.cloneElement(triggerButton, {
    onClick: open,
  });

  const darkMode = useColorModeContext();

  return (
    <>
      {trigger}
      {/* @ts-ignore */}
      <Dialog isOpen={isOpen} onClose={close} initialFocus={cancelButtonRef}>
        <div
          className={`${
            darkMode[0] ? `bg-dim-300` : `bg-white`
          } inline-block align-bottom rounded-lg px-4 pt-5 pb-4 text-left
         overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg
          sm:z-100 sm:w-full sm:p-6`}
        >
          <div className="sm:flex flex-col sm:items-center">
            {icon === 'danger' && (
              <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                <ExclamationIcon
                  className="h-6 w-6 text-red-600"
                  aria-hidden="true"
                />
              </div>
            )}

            {icon === 'info' && (
              <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                <InformationCircleIcon
                  className="h-6 w-6 text-blue-600"
                  aria-hidden="true"
                />
              </div>
            )}
            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
              <DialogTitle
                as="h3"
                className={`${darkMode[0] ? `text-white` : `text-gray-900`} 
                text-lg leading-6 font-medium text-center`}
              >
                {title}
              </DialogTitle>
              {body && (
                <div className="mt-2">
                  <p className="text-sm text-gray-500">{body}</p>
                </div>
              )}
            </div>
          </div>
          <div className="mt-4 flex space-x-2 justify-center">
            <Button
              type="button"
              variant="inverse"
              className="w-full inline-flex justify-center rounded-md border focus:ring-1 focus:ring-offset-1 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
              onClick={close}
              ref={cancelButtonRef}
            >
              {cancelButtonText}
            </Button>
            {confirmButton}
          </div>
        </div>
      </Dialog>
    </>
  );
};
