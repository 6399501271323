import clsx from 'clsx';
import { FieldWrapper } from './FieldWrapper';
import { useColorModeContext } from 'context/ColorContext';

export const InputFieldpg = props => {
  const {
    type,
    label,
    className,
    maxLength,
    caption,
    step,
    registration,
    error,
  } = props;

  const darkMode = useColorModeContext();
  // limit image dimensions to 50x50

  return (
    <FieldWrapper label={label} error={error}>
      <div className="text-xs my-1 text-gray-400">{caption}</div>
      <input
        type={type}
        step={step}
        maxLength={maxLength}
        className={clsx(
          `border border-solid ${error && `border-red-500`} ${
            darkMode[0] ? `bg-dim-100` : `bg-gray-100`
          } border-gray-100 rounded-md w-full outline-none 
          focus:outline-none px-8 py-3 dark:bg-gray-900`,
          className
        )}
        {...registration}
      />
    </FieldWrapper>
  );
};
