import { axios } from 'lib/axios';
import { queryClient } from 'lib/react-query';
import { useMutation } from 'react-query';

type ScheduleADemo = {
  data: {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    industry: string;
    hearAboutUs: string;
  };
};

export const scheduleADemo = ({ data }: ScheduleADemo) => {
  return axios.post(`/tapp/notes/demo`, data);
};

export const useScheduleADemo = ({ config = {} } = {}) => {
  return useMutation({
    onSuccess: data => {
      queryClient.invalidateQueries('demos');
    },
    ...config,
    mutationFn: scheduleADemo,
  });
};
