// @ts-nocheck
import logo from 'assets/logo-dark.svg';
import { Link } from 'react-router-dom';
import { useAuth } from 'lib/auth';

export const AuthNavBar = ({ option }) => {
  const { isAuthenticated, user } = useAuth();

  function getInitials(firstName, lastName) {
    const initials = `${firstName?.charAt(0) || ''}${
      lastName?.charAt(0) || ''
    }`;
    return initials.toUpperCase();
  }

  return (
    <div className="absolute left-0 top-0 my-4 w-full uppercase dark:text-white">
      <div className="mx-auto flex max-w-7xl items-center justify-between px-4 pb-8 pt-2 sm:px-6 lg:px-8">
        <Link to="/">
          <img src={logo} alt="logo" className="h-8 w-auto" />
        </Link>
        <div className="flex items-center space-x-4">
          <Link to="/about" className="hidden px-4 py-2 font-semibold md:block">
            About
          </Link>
          {isAuthenticated ? (
            <>
              <div className="flex h-8 w-8 items-center justify-center rounded-full bg-black bg-cover font-[1.5rem] text-white dark:border dark:border-white md:h-12 md:w-12">
                {getInitials(user.first_name, user.last_name)}
              </div>
            </>
          ) : (
            <>
              {option === 'login' ? (
                <Link
                  to="/auth/create-new-account"
                  className="rounded bg-flamingo-500 px-4 py-2 text-gray-100"
                >
                  SIGN UP
                </Link>
              ) : option === 'create-new-account' ? (
                <Link
                  to="/auth/login"
                  className="rounded bg-flamingo-500 px-4 py-2 text-gray-100"
                >
                  LOGIN
                </Link>
              ) : (
                ''
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};
