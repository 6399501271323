import { Button } from 'components/elements';
import { Form, FormDrawer, InputField } from 'components/form';
import { useUpdateTrade } from '../api/updateTrade';
import { useColorModeContext } from 'context/ColorContext';
import { InputQuantity } from 'components/form/InputQuantity';
import { InputFieldpg } from 'components/form/InputFieldGain';
import './../assets/frameclick.css';

export const UpdateTrade = ({
  pk,
  quantity,
  entry_price,
  price_target,
  ticker,
  notif_trigger_pct_gain_low,
  notif_trigger_pct_gain_high,
}: {
  pk: string;
  quantity: number;
  entry_price: number;
  price_target: number;
  ticker: string;
  notif_trigger_pct_gain_low: number;
  notif_trigger_pct_gain_high: number;
}) => {
  const updateTradeMutation = useUpdateTrade();
  const darkMode = useColorModeContext();
  const colorScheme = darkMode[0] ? 'dark' : 'light';

  return (
    <>
      <FormDrawer
        size="sm"
        isDone={updateTradeMutation.isSuccess}
        triggerButton={
          <button
            className={`trigger-button flex items-center justify-center m-2 mx-4 cursor-pointer ${colorScheme}`}
          >
            <span>Change Entries</span>
          </button>
        }
        title={`Edit Target Price: Ticker - ${ticker}`}
        submitButton={
          <Button
            form="update-watchlist-trade"
            type="submit"
            size="sm"
            isLoading={updateTradeMutation.isLoading}
          >
            Submit
          </Button>
        }
      >
        <Form
          id="update-watchlist-trade"
          onSubmit={async (values: any) => {
            values.pk = pk;
            const data = Object.fromEntries(
              Object.entries(values).filter(([key, value]) => value !== '')
            );
            // @ts-ignore
            await updateTradeMutation.mutateAsync({
              data,
            });
          }}
          options={{
            defaultValues: {
              quantity,
              entry_price,
              price_target,
              notif_trigger_pct_gain_low,
              notif_trigger_pct_gain_high,
            },
          }}
          // schema={schema}
        >
          {({ register, formState }: { register: any; formState: any }) => (
            <>
              <InputField
                type="number"
                step={0.001}
                label="Entry price"
                required={true}
                error={formState.errors['entry_price']}
                registration={register('entry_price')}
                caption=""
              />
              <InputQuantity
                type="number"
                step={0.01}
                label="Quantity"
                required={true}
                error={formState.errors['quantity']}
                registration={register('quantity')}
              />
              <InputField
                type="number"
                step={0.001}
                label="Target Price"
                required={true}
                error={formState.errors['price_target']}
                registration={register('price_target')}
              />
              <div
                className={`${
                  darkMode[0] ? `bg-dim-300` : `bg-gray-200`
                } rounded-md px-4`}
              >
                <div
                  className={`${
                    darkMode[0] ? `text-gray-300` : `text-gray-800`
                  } text-sm py-3 pt-5 capitalize font-bold p-2`}
                >
                  Percent Return Alert Range
                </div>
                <div className="p-2">
                  <InputFieldpg
                    type="number"
                    step={0.01}
                    required={true}
                    label="Lower Limit (%)"
                    error={formState.errors['notif_trigger_pct_gain_low']}
                    registration={register('notif_trigger_pct_gain_low')}
                  />
                  <InputFieldpg
                    type="number"
                    step={0.01}
                    required={true}
                    label="Upper Limit (%)"
                    error={formState.errors['notif_trigger_pct_gain_high']}
                    registration={register('notif_trigger_pct_gain_high')}
                  />
                </div>
              </div>
            </>
          )}
        </Form>
      </FormDrawer>
    </>
  );
};
