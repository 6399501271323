import { isValidJSON } from './isValidJSON'
import CryptoJS from 'crypto-js'

const storagePrefix = 'msw-db'
const encryptionKey = 'oh87h87h87wh4fg34gfiwg7wg346fgo84h37o'

const storage = {
  encrypt: (data: any) => {
    const encryptedData = CryptoJS.AES.encrypt(data, encryptionKey).toString()
    return encryptedData
  },

  decrypt: (encryptedData: any) => {
    try {
      const decryptedBytes = CryptoJS.AES.decrypt(encryptedData, encryptionKey)
      const decryptedData = decryptedBytes.toString(CryptoJS.enc.Utf8)
      return decryptedData
    } catch (error) {
      console.error('Decryption error:', error)
      return null
    }
  },
  setToken: (token: any) => {
    const encryptedToken = storage.encrypt(JSON.stringify(token))
    window.sessionStorage.setItem(`${storagePrefix}token`, encryptedToken)
  },

  getToken: () => {
    const encryptedToken = window.sessionStorage.getItem(
      `${storagePrefix}token`
    )
    if (!encryptedToken) return null
    const decryptedToken = storage.decrypt(encryptedToken)
    //@ts-ignore
    return isValidJSON(decryptedToken) ? JSON.parse(decryptedToken) : null
  },
  setRefreshToken: (token: string) => {
    const encryptedToken = storage.encrypt(JSON.stringify(token))
    window.sessionStorage.setItem(
      `${storagePrefix}refreshtoken`,
      encryptedToken
    )
  },

  getRefreshToken: () => {
    const encryptedToken = window.sessionStorage.getItem(
      `${storagePrefix}refreshtoken`
    )
    if (!encryptedToken) return null
    const decryptedToken = storage.decrypt(encryptedToken)
    return decryptedToken && isValidJSON(decryptedToken)
      ? JSON.parse(decryptedToken)
      : null
  },
  setErrorMessage: (error: any) => {
    sessionStorage.setItem('errorMessage', JSON.stringify(error))
  },
  setErrorResponse: (error: any) => {
    sessionStorage.setItem('errorResponse', JSON.stringify(error))
  },
  setActivePortfolio: (portfolio_id: any) => {
    const encryptedPortfolio = storage.encrypt(JSON.stringify(portfolio_id))
    window.sessionStorage.setItem(
      `${storagePrefix}selectedPortfolioId`,
      encryptedPortfolio
    )
  },
  getActivePortfolio: (): string => {
    const encryptedPortfolio = window.sessionStorage.getItem(
      `${storagePrefix}selectedPortfolioId`
    )
    if (!encryptedPortfolio) return null
    const decryptedPortfolio = storage.decrypt(encryptedPortfolio)
    return decryptedPortfolio && isValidJSON(decryptedPortfolio)
      ? JSON.parse(decryptedPortfolio)
      : null
  },

  setActivePortfolioName: (portfolio: string) => {
    sessionStorage.setItem('selectedPortfolioName', JSON.stringify(portfolio))
  },
  getActivePortfolioName: () => {
    const portfolioName = sessionStorage.getItem('selectedPortfolioName')
    return portfolioName ? JSON.parse(portfolioName) : null
  },
  getErrorMessage: () => {
    const errorMessage = sessionStorage.getItem('errorMessage')
    return errorMessage ? JSON.parse(errorMessage) : null
  },
  getErrorResponse: () => {
    const errorResponse = sessionStorage.getItem('errorResponse')
    return errorResponse ? JSON.parse(errorResponse) : null
  },
  clearError: () => {
    sessionStorage.removeItem(`errorMessage`)
    sessionStorage.removeItem(`errorResponse`)
  },
  clearToken: () => {
    sessionStorage.removeItem(`${storagePrefix}token`)
  },
  clearStorage: () => {
    sessionStorage.clear()
  },
  clearActivePortfolio: () => {
    window.sessionStorage.removeItem(`${storagePrefix}selectedPortfolioId`)
  },
}

export default storage
