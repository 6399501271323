// @ts-nocheck
import { Link } from 'react-router-dom'
import { AuthLayout } from '../components/AuthLayout'

export const ResetPasswordSuccess = () => {
  return (
    <AuthLayout option="reset-password-success">
      <div className="my-32 text-center">
        <h1 className="mb-4 text-3xl font-bold md:text-4xl">Success!</h1>
        <p className="mb-8 text-lg md:text-xl">
          Your password has been reset. Proceed to login.
        </p>
      </div>
      <Link
        to="/auth/login"
        className="w-fit rounded-full px-8 py-1.5 outline-none focus:outline-none"
      >
        Back to login
      </Link>
    </AuthLayout>
  )
}
