// @ts-nocheck
import { useState } from 'react'
import { AuthLayout } from '../components/AuthLayout'
import { Button } from 'components/elements'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { InputFieldV2 } from 'components/form/InputFieldv2'
import { passwordRecovery } from 'features/auth'

export const ForgotPassword = () => {
  const [email, setEmail] = useState('')
  const navigate = useNavigate()

  const handleEmailChange = e => {
    setEmail(e.target.value)
  }

  const handlePasswordReset = async () => {
    // Perform a fetch or Axios request to your backend to initiate the password reset
    try {
      const response = await passwordRecovery({ email })
      if (response.ok) {
        // Password reset initiated successfully
        console.log('Password reset initiated.')
      } else {
        // Handle error cases, e.g., email not found
        console.error('Password reset failed.')
      }
    } catch (error) {
      console.error('Password reset request failed:', error)
    }
    navigate('/auth/reset-password-info')
  }

  return (
    <AuthLayout option="forgot-password">
      <div className="">
        <h1 className="my-12 text-4xl font-bold">Forgot Password?</h1>
        <form className="flex w-full flex-col items-center gap-6">
          <InputFieldV2
            value={email}
            onChange={handleEmailChange}
            type="email"
            className="w-full rounded-full border border-solid border-gray-300 bg-gray-50 px-8 py-1.5 outline-none focus:outline-none"
            placeholder="Email address"
            maxLength={50}
          />
          {/* <button onClick={handlePasswordReset}>Recover Password</button> */}

          <Button onClick={handlePasswordReset}>Recover Password</Button>
          <Link
            to="/auth/login"
            className="w-fit rounded-full px-8 py-1.5 outline-none focus:outline-none"
          >
            Back to login
          </Link>
        </form>
      </div>
    </AuthLayout>
  )
}
