// @ts-nocheck
import { MainLayout } from 'components/layout'
import { useEffect } from 'react'
import { Header } from '../components/Header'

export const Referral = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])
  return (
    <MainLayout headerContent={<Header start="Referral Agreeme" tail="nt" />}>
      <div className="container mx-auto px-4 py-8">
        <p className="mb-4">
          <strong>Effective Date:</strong> May 15, 2024
        </p>
        <p className="mb-4">
          This Referral Agreement (“Agreement”) has been adopted by TickerZone,
          INC (“TickerZone”), to inform you, either the referrer (“Referrer,” as
          more thoroughly defined below) or the referral (“Referral,” as more
          thoroughly defined below”) of your rights and duties when
          participating in the TickerZone Referral Program (“Referral Program”).
          Your participation in the Referral Program constitutes your
          manifestation of assent to and intent to be bound by the terms and
          conditions of this Agreement.
        </p>
        <p className="mb-4">
          <strong>
            YOU UNDERSTAND AND AGREE THAT YOU HAVE A DUTY TO READ THIS
            AGREEMENT.
          </strong>
        </p>
        <p className="mb-4">
          By participating in the Referral Program, you manifest your assent and
          agree to be bound by this Agreement. If you do not agree to the terms
          and conditions contained within this Agreement, you must discontinue
          your participation in the Referral Program immediately. TickerZone
          reserves the right to suspend, replace, modify, amend, or terminate
          this Agreement at any time and within its sole and absolute
          discretion. In the event TickerZone replaces, modifies, or amends this
          Agreement, the Effective Date, located at the top of this Agreement,
          will change. Your continued participation in the Referral Program
          after a change in the Effective Date above will constitute your
          manifestation of assent to, and agreement with, any replacement,
          modification, or amendment herein.
        </p>
        <p className="mb-4">
          <strong>
            THIS REFERRAL AGREEMENT CONTAINS AN ARBITRATION PROVISION AND A
            CLASS ACTION WAIVER THAT AFFECT YOUR LEGAL RIGHTS. YOU HAVE A DUTY
            TO READ THIS AGREEMENT AND THE ARBITRATION PROVISION AND CLASS
            ACTION WAIVER CONTAINED WITHIN. IF YOU DO NOT AGREE TO BE BOUND BY
            THE TERMS OF THIS AGREEMENT, ITS ARBITRATION PROVISION, OR ITS CLASS
            ACTION WAIVER, YOU MUST IMMEDIATELY DISCONTINUE YOUR PARTICIPATION
            IN THE REFERRAL PROGRAM.
          </strong>
        </p>
        <h2 className="mb-2 mt-6 text-2xl font-bold">1. Definitions</h2>
        <ul className="mb-4 list-inside list-disc">
          <li>
            <strong>Advertising Materials</strong> is defined as pay per click
            advertisements, display advertisements, landing pages, web pages,
            newsletters, email blasts, hypertext links, or other content used to
            advertise the availability of TickerZone’s services.
          </li>
          <li>
            <strong>Coupon Code</strong> means a unique code provided by
            TickerZone to Referrer so that Referrer may provide the code to a
            Lead to confirm that the Lead was referred to TickerZone by
            Referrer.
          </li>
          <li>
            <strong>Lead</strong> is defined as an individual or business entity
            that contacts TickerZone through efforts that are directly
            attributable to Referrer.
          </li>
          <li>
            <strong>Referrer</strong> is defined as any individual or business
            entity that has agreed to this Agreement and is authorized to
            participate in the Referral Program and refer Leads to TickerZone
            under the terms of this Agreement.
          </li>
          <li>
            <strong>Referral</strong> is defined as a qualified Lead approved by
            TickerZone that has used a Coupon Code to purchase services from
            TickerZone.
          </li>
          <li>
            <strong>Referral Discount</strong> is defined as a discount in the
            price of TickerZone’s services that is provided to a Referral.
          </li>
          <li>
            <strong>Referral Fee</strong> is defined as the amount paid by
            TickerZone to Referrer for a Referral, which will be listed on the
            TickerZone website and may be changed at any time within
            TickerZone’s sole and absolute discretion.
          </li>
          <li>
            <strong>User</strong> is defined as a potential lead, including, but
            not limited to, users of third-party websites.
          </li>
        </ul>
        <h2 className="mb-2 mt-6 text-2xl font-bold">
          2. Warranties, representations, and eligibility
        </h2>
        <p className="mb-4">
          Each Referrer and Referral warrants and agrees that he or she has the
          right and legal capacity to enter into this agreement, that each
          Referrer or Referral is a human individual age eighteen (18) or above,
          and that each Referrer or Referral is not prohibited from entering
          into this Agreement by any preexisting agreement with a third party.
          If Referrer or Referral is participating in this Referral Program on
          behalf of a third party, each Referrer or Referral warrants that each
          Referrer or Referral is an actual and authorized agent of that third
          party and has the authority and capacity to bind that third party to
          the terms and conditions of this Agreement. If each Referrer is a
          current client of TickerZone, Referrer understands and agrees that, in
          order to participate in this Referral Program, Referrer must maintain
          a good credit standing with TickerZone and Referrer’s account must not
          be in arrears. Each Referrer or Referral agrees to provide TickerZone
          with accurate, current, and complete contact information and to update
          that contact information if and when it changes.
        </p>
        <h2 className="mb-2 mt-6 text-2xl font-bold">
          3. Participation in Referral Program
        </h2>
        <p className="mb-4">
          TickerZone will pay Referrers a Referral Fee for all Referrals. The
          Referral Fee paid by TickerZone will be posted to the TickerZone
          Referral Program web page and may be amended, modified, replaced, or
          suspended from time to time. TickerZone reserves the right to amend,
          modify, replace, or suspend the Referral Fee paid to Referrer at any
          time and within its sole and absolute discretion. TickerZone will
          approve Leads within its sole and absolute discretion and consistent
          with its established policies for accepting or rejecting clients. By
          participating in the Referral Program, Referrer warrants and agrees
          that it will not circumvent, tamper with, modify, or compromise the
          technological tracking and protection measures implemented by
          TickerZone in furtherance of the Referral Program, including, but not
          limited to, the unauthorized modification of the number of Referrals
          calculated by TickerZone. Referrer’s failure to strictly comply with
          the terms and conditions of this Agreement may result in the
          termination of this Agreement and the forfeiture of any Referral Fees
          owed to Referrer pursuant to this Agreement.
        </p>
        <h2 className="mb-2 mt-6 text-2xl font-bold">
          4. Approval of Advertising Materials
        </h2>
        <p className="mb-4">
          Referrers may advertise the services of TickerZone in compliance with
          the terms of this Agreement. Referrer is expressly prohibited from
          advertising the services of TickerZone in association with:
        </p>
        <ul className="mb-4 list-inside list-disc">
          <li>
            Websites or other services that collect personal or personally
            identifiable information from a User without his or her express
            consent;
          </li>
          <li>
            Websites or other services that contain or display obscene, sexually
            explicit, illegal, pornographic, racist, offensive, or derogatory
            content;
          </li>
          <li>
            Websites or other services that promote bodily harm to or harassment
            of third parties;
          </li>
          <li>
            Websites or other services that violate the rights of third parties,
            including intellectual property or other personal or proprietary
            rights;
          </li>
          <li>
            Websites or other services that violate any applicable law, statute,
            treaty, regulation, or ordinance, whether local, state, provincial,
            national, or international; and
          </li>
          <li>
            Websites or other services that contain any other objectionable
            content to be defined within TickerZone’s sole and absolute
            discretion.
          </li>
        </ul>
        <p className="mb-4">
          Referrers may use Advertising Materials to truthfully and accurately
          advertise the availability of TickerZone’s services consistent with
          the terms of this Agreement. Referrer agrees to comply with all
          applicable advertising laws, regulations, ordinances, statutes, or
          treaties, whether local, state, provincial, national, or
          international, including, but not limited to, the CAN SPAM Act, Part
          255 of the FTC false advertising regulations, and state false
          advertising law. TickerZone reserves the right to terminate this
          Agreement and cancel Referrer’s participation in the Referral Program
          for Referrer’s failure to comply with this paragraph.
        </p>
        <h2 className="mb-2 mt-6 text-2xl font-bold">5. Payments</h2>
        <p className="mb-4">
          TickerZone will calculate the number of Referrals generated by
          Referrer and pay Referrer for all Referrals on a monthly basis. Within
          thirty-one (31) days of its receipt of a Referral, TickerZone will
          calculate the number of Referrals generated by Referrer and provide
          Referrer with a report disclosing the Referral Fee amount to be paid
          to Referrer. TickerZone will then remit payment to Referrer at the
          address or payment method provided by Referrer and stored within the
          records of TickerZone. In the event the Referral Fee to be paid to
          Referrer in any thirty-one (31) day period is less than $50,
          TickerZone may hold the Referral Fee to be paid to Referrer until the
          next period in which the Referral Fee to be paid to Referrer equals or
          exceeds $50. Referrer understands and agrees that he or she has an
          ongoing duty to update its contact information if and when it changes
          and, in the event any Referral Fee is returned to TickerZone due to an
          incorrect address, TickerZone will not resend or reissue the returned
          Referral Fee to Referrer.
        </p>
        <h2 className="mb-2 mt-6 text-2xl font-bold">6. Referral Discounts</h2>
        <p className="mb-4">
          TickerZone may provide a Referral Discount to a Lead to incentivize a
          Lead to sign up for TickerZone’s services and become a Referral. To
          take advantage of a Referral Discount, a Lead must enter the Coupon
          Code provided to it by a Referrer when signing up for TickerZone’s
          services and sign up for TickerZone’s service. The Referral Discount
          provided by TickerZone to a Lead will be posted to the TickerZone
          Referral Program web page or the unique sign-up link and page provided
          to a Referrer and may be amended, modified, replaced, or suspended
          from time to time. TickerZone reserves the right to amend, modify,
          replace, or suspend the Referral Discount at any time and within its
          sole and absolute discretion.
        </p>
        <h2 className="mb-2 mt-6 text-2xl font-bold">
          7. Trademarks and Copyrighted Works
        </h2>
        <p className="mb-4">
          Referrer and Referral acknowledge and agrees that any and all
          trademarks, trade names, design marks, or logos displayed on the
          TickerZone website or marketing materials, including but not limited
          to the TickerZone mark, are common law or registered trademarks owned
          by or licensed to TickerZone. Referrer and Referral are expressly
          prohibited from using the trademarks of TickerZone to cause confusion
          in, to cause mistake in, or to deceive consumers, or from falsely
          designating the origin of, the source of, or the sponsorship of
          Referrer or Referral’s goods or services. Referrer and Referral are
          further prohibited from using the trademarks of TickerZone in domain
          names, in keyword advertisements, to trigger keyword advertisements,
          or in meta tags. Referrers may make a nominative fair use of the
          trademarks of TickerZone consistent with trademark law and Section 4
          of this Agreement while participating in the Referral Program.
          Referrer and Referral are also prohibited from using the copyrighted
          content of TickerZone, including, but not limited to, TickerZone’s
          logos, copy, and website content.
        </p>
        <h2 className="mb-2 mt-6 text-2xl font-bold">
          8. Term and Termination
        </h2>
        <p className="mb-4">
          Each party may terminate this Agreement at any time and without cause.
          Either party may terminate this Agreement if the other party is in
          material breach of this Agreement and has not cured such breach within
          five (5) calendar days of written notice from the non-breaching party
          that specifies the breach. TickerZone may terminate this Agreement if,
          at any time, Referrer or Referral (i) breaches any term or condition
          of this Agreement; (ii) becomes insolvent or files an assignment for
          the benefit of creditors; (iii) is the subject of an insolvency
          proceeding, including, but not limited to, an involuntary bankruptcy;
          (iv) violates the rights of third parties, including, but not limited
          to, intellectual or other personal or proprietary rights; or (iv)
          voluntarily files a petition for bankruptcy. Termination of this
          Agreement hereunder will not limit a party from pursuing any other
          remedies available to it, including injunctive relief. Any provision
          of this Agreement that contemplates performance or observance
          subsequent to termination or expiration of this Agreement will survive
          termination or expiration hereof and will continue in full force and
          effect.
        </p>
        <h2 className="mb-2 mt-6 text-2xl font-bold">
          9. Limitation of Liability
        </h2>
        <p className="mb-4">
          EXCEPT AS OTHERWISE PROVIDED HEREIN, THE REFERRAL PROGRAM, ITS USE,
          AND REFERRER OR REFERRAL’S RESULTS OF SUCH USE ARE PROVIDED ON AN “AS
          IS'' BASIS. TO THE FULLEST EXTENT PERMISSIBLE PURSUANT TO APPLICABLE
          LAW, TickerZone MAKES NO WARRANTIES (INCLUDING THE IMPLIED WARRANTIES
          OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NONINFRINGEMENT,
          OR THE ABSENCE OF LATENT OR OTHER DEFECTS), GUARANTEES,
          REPRESENTATIONS, PROMISES, STATEMENTS, ESTIMATES, CONDITIONS, OR OTHER
          INDUCEMENTS, EXPRESS, IMPLIED, ORAL, WRITTEN, OR OTHERWISE, EXCEPT AS
          EXPRESSLY SET FORTH HEREIN. NEITHER PARTY IS RESPONSIBLE FOR DELAYS
          CAUSED BY ACCIDENT, WAR, ACTS OF GOD, EMBARGO, COMPUTER SYSTEM FAILURE
          OR ANY OTHER CIRCUMSTANCE BEYOND ITS CONTROL. TickerZone WILL MAKE
          EVERY REASONABLE EFFORT TO MEET SCHEDULED OR ESTIMATED DELIVERY DATES
          BUT MAKES NO GUARANTEE AND ACCEPTS NO LIABILITY FOR ITS FAILURE TO
          MEET SAID DATES. NEITHER PARTY SHALL BE LIABLE FOR ANY PUNITIVE,
          SPECIAL OR EXEMPLARY DAMAGES OR INDIRECT, INCIDENTAL OR CONSEQUENTIAL
          LOSSES, COSTS OR EXPENSES OF ANY KIND WHATSOEVER AND HOWEVER CAUSED,
          WHETHER ARISING UNDER CONTRACT, TORT, NEGLIGENCE, STATUTE OR
          OTHERWISE, INCLUDING (WITHOUT LIMITATION) LOSS OF PRODUCTION, LOSS OF
          OR CORRUPTION TO DATA, LOSS OF PROFITS OR OF CONTRACTS, BUSINESS
          INTERRUPTION, LOSS OF OPERATION TIME AND LOSS OF GOODWILL OR
          ANTICIPATED SAVINGS, EVEN IF ADVISED OF THE POSSIBILITY OF SUCH
          DAMAGES, LOSS, COST OR EXPENSE. OTHER THAN AS SET FORTH HEREIN, EACH
          PARTY’S TOTAL OBLIGATIONS AND/OR LIABILITY, IF ANY HEREUNDER, SHALL BE
          LIMITED TO THE AMOUNTS TickerZone HAS PAID TO REFERRER.
        </p>
        <h2 className="mb-2 mt-6 text-2xl font-bold">10. Indemnification</h2>
        <p className="mb-4">
          Referrer and Referral assume full and complete responsibility and
          liability for their participation in the Referral Program and will
          indemnify and hold TickerZone, its directors, officers, employees, and
          agents harmless from and against all demands, claims, or liability
          arising out of or relating to Referrer’s Advertising Materials or
          Referrer or Referral’s participation in the Referral Program. Referrer
          warrants that its Advertising Materials will be truthful, accurate,
          and will comply with all local, state, federal, and international laws
          concerning the advertisement and sale of goods or services. Referrer
          and Referral understands and agrees that they will indemnify, defend,
          and hold harmless TickerZone, its officers, directors, employees,
          affiliates, independent contractors, and agents, for any and all
          damages, losses, claims, actions, costs, judgments, liabilities, and
          expenses, including attorneys’ fees and costs, arising out of or
          related to any: (i) in the case of Referrer, claim by any third party
          that any of Referrer’s Advertising Materials infringe a third party’s
          copyright, trademark, patent, trade secret, or other intellectual
          property rights; (ii) in the case of Referrer, claim by any third
          party that any of Referrer’s Advertising Materials violate the rights
          of a third party, are defamatory or obscene, or violate any ordinance,
          law, regulation, or other judicial or administrative action; or (iii)
          breach by Referrer or Referral of any representation or warranty set
          forth in this Agreement. A third party means any entity other than the
          parties to this Agreement and their respective directors, officers,
          employees, and agents. If any action is or will be brought against
          TickerZone with respect to any allegation for which indemnity may be
          sought, Referrer or Referral, whichever is applicable, will provide
          reasonable cooperation to TickerZone, at Referrer or Referral’s
          expense, to defend against or settle any such claim. Referrer or
          Referral’s obligation to defend TickerZone under the terms of this
          Agreement will not provide Referrer or Referral with the ability to
          control TickerZone’s defense, and TickerZone reserves the right to
          control its defense and select its counsel.
        </p>
        <h2 className="mb-2 mt-6 text-2xl font-bold">11. Assignment</h2>
        <p className="mb-4">
          Referrer or Referral may not assign, in whole or in part, any rights,
          obligations, or duties created by or under this Agreement. TickerZone
          may assign its rights, obligations, or duties under this Agreement at
          any time upon written notice to Referrer or Referral, including in a
          sale or transfer of all or substantially all of TickerZone’s assets,
          in a sale or transfer of TickerZone’s accounts, or in a merger of
          TickerZone with another company. Should TickerZone assign its rights,
          obligations, or duties under this Agreement, TickerZone’s rights,
          obligations, or duties under this Agreement will be binding on, and
          shall insure to the benefit of, its successor or assign.
        </p>
        <h2 className="mb-2 mt-6 text-2xl font-bold">12. Survival</h2>
        <p className="mb-4">
          If any provision of this Agreement is held to be illegal, invalid, or
          unenforceable, and if the rights or obligations of either party under
          this Agreement will not be materially and adversely affected, (i) said
          provision will be fully severable; (ii) this Agreement will be
          construed and enforced as if said provision had never comprised a part
          of this Agreement; (iii) the remaining provisions of this Agreement
          will remain in full force and effect and will not be affected by the
          severance of any provision; and (iv) there will be added a legal,
          valid, and enforceable provision as similar in terms to said provision
          as may be possible in lieu of said provision.
        </p>
        <h2 className="mb-2 mt-6 text-2xl font-bold">13. Choice of Laws</h2>
        <p className="mb-4">
          This Agreement will be governed by and interpreted in accordance with
          the laws of the State of North Carolina. Referral, Referrer, and
          TickerZone’s sole and exclusive forum and remedy at law for any
          disputes arising out of or related to this Agreement, including the
          interpretation thereof, shall be binding arbitration. Arbitration
          under this Agreement will be held in Raleigh, North Carolina and will
          be conducted by a single arbitrator, who shall be randomly selected
          from a panel of arbitrators maintained by the American Arbitration
          Association. The parties agree that the arbitrator will have the power
          to award damages, injunctive relief, and attorneys’ fees and expenses
          to the prevailing party. Any award rendered by the arbitrator shall be
          final and binding on each of the parties, and judgment may be entered
          thereon in any court of competent jurisdiction. The parties agree that
          the prevailing party in any arbitration will be entitled to injunctive
          relief in any court of competent jurisdiction to enforce the
          arbitration award. The parties agree that any arbitration will be
          confidential and the existence of the arbitration and any element of
          it (including, but not limited to, any pleadings, briefs, documents,
          or other evidence submitted or exchanged and any testimony or other
          oral submissions and awards) will not be disclosed beyond the
          arbitration proceedings, except as may lawfully be required in
          judicial proceedings relating to the arbitration or by applicable
          disclosure rules and regulations of securities regulatory authorities
          or other governmental agencies.
        </p>
        <h2 className="mb-2 mt-6 text-2xl font-bold">14. Waiver</h2>
        <p className="mb-4">
          The waiver by either party of a breach of or a default under any
          provision of this Agreement, must be in writing and shall not be
          construed as a waiver of any subsequent breach of or default under the
          same or any other provision of this Agreement, nor shall any delay or
          omission on the part of either party to exercise or avail itself of
          any right or remedy that it has or may have hereunder operate as a
          waiver of any right or remedy.
        </p>

        <h2 className="mb-2 mt-6 text-2xl font-bold">
          15. Relationship of Parties
        </h2>
        <p className="mb-4">
          Nothing in this Agreement will be deemed or construed as creating a
          joint venture, partnership, employment, or fiduciary relationship
          between the parties. Neither party has any authority of any kind to
          bind the other party. The relationship of the parties is, and at all
          times shall continue to be, that of independent contractors.
        </p>

        <h2 className="mb-2 mt-6 text-2xl font-bold">16. Force Majeure</h2>
        <p className="mb-4">
          Except for the payment of monies due hereunder, neither party shall be
          responsible or have any liability for any delay or failure to perform
          to the extent due to unforeseen circumstances or causes beyond its
          reasonable control, including, without limitation, acts of God,
          earthquake, fire, flood, embargoes, labor disputes and strikes, riots,
          war, Internet or other network “brownouts” or failures, power
          failures, and acts of civil and military authorities; provided that
          such party gives the other party prompt written notice of the failure
          to perform and the reason therefore and uses its reasonable efforts to
          limit the resulting delay in its performance. To the extent that a
          force majeure has continued for ten (10) business days, Referrer,
          Referral, and TickerZone may terminate the Agreement, excluding
          payment obligations, without penalty.
        </p>

        <h2 className="mb-2 mt-6 text-2xl font-bold">17. Integration</h2>
        <p className="mb-4">
          This Agreement, including any exhibit(s), addendum (addenda) or
          rider(s) attached hereto, constitutes the entire agreement between the
          parties concerning the subject matter hereof, and supersedes (i) all
          prior or contemporaneous representations, discussions, proposals,
          negotiations, conditions, agreements, and communications, whether oral
          or written, between the parties relating to the subject matter, and
          (ii) all past courses of dealing and industry custom. No amendment or
          modification of any provision of this Agreement, exhibit, addendum or
          rider shall be effective unless in writing and signed by a duly
          authorized representative of each party.
        </p>
      </div>
    </MainLayout>
  )
}
