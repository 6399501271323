// @ts-nocheck
import { axios } from 'lib/axios';
import { useQuery } from 'react-query';

const getActiveTrades = async (portfolioId, tickerType = 'stock') => {
  if (!portfolioId) {
    throw new Error('Portfolio ID is required');
  }

  const endpoint =
    tickerType === 'option'
      ? `/tapp/options/holdings?portfolio_pk=${portfolioId}`
      : `/tapp/trades/positions?portfolio_pk=${portfolioId}`;

  const response = await axios.get(endpoint);
  return response;
};

export const useActiveTrades = (options = {}) => {
  const { selectedPortfolio, tickerType = 'stock', enabled = true } = options;
  const isQueryEnabled = enabled && !!selectedPortfolio;

  const query = useQuery(
    ['activeTrades', selectedPortfolio, tickerType], // Added tickerType to query key
    () => getActiveTrades(selectedPortfolio, tickerType),
    {
      enabled: isQueryEnabled,
      staleTime: 30000,
      placeholderData: [],
      select: data => (isQueryEnabled ? data : []),
    }
  );

  return {
    ...query,
    data: isQueryEnabled ? query.data : [],
    isLoading: isQueryEnabled ? query.isLoading : false,
  };
};
