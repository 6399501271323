import { Toaster } from 'react-hot-toast';
import { ColorModeProvider } from 'context/ColorContext';
import { AppProvider } from 'providers/app';
import { AppRoutes } from 'routes';

function App() {
  return (
    <ColorModeProvider>
      <AppProvider>
        <AppRoutes />
        <Toaster
          position="top-right"
          toastOptions={{
            duration: 4000,
            style: {
              background: '#363636',
              color: '#fff',
              borderRadius: '8px',
              padding: '16px',
            },
            success: {
              style: {
                background: '#059669', // green-600
              },
            },
            error: {
              style: {
                background: '#DC2626', // red-600
              },
            },
          }}
        />
      </AppProvider>
    </ColorModeProvider>
  );
}

export default App;
