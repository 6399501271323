import React, { Suspense, useEffect, useRef } from 'react';
import {
  GetStarted,
  Landing,
  License,
  NotFound,
  PrivacyPolicy,
  Referral,
  Terms,
  TermsOfUse,
  ThankYou,
} from 'features/misc';

import { useAuth } from 'lib/auth';
import { protectedRoutes } from './protected';
import { publicRoutes } from './public';
import { useRoutes } from 'react-router-dom';
import { FullScreenLoader } from 'components/elements';
import { ErrorBoundary } from 'react-error-boundary';

const ResetPassword = React.lazy(
  //@ts-ignore
  () => import('features/auth/routes/ResetPassword')
);

//@ts-ignore
const PortfolioLanding = React.lazy(() => import('features/auth'));

export const AppRoutes = () => {
  const { isAuthenticated, isLoading } = useAuth();
  const hasReloaded = useRef(false);
  useEffect(() => {
    if (isAuthenticated && !isLoading && !hasReloaded) {
      //@ts-ignore
      hasReloaded.current = true;
      setTimeout(() => {
        window.location.reload();
      }, 2000); // 2000 milliseconds = 2 seconds
    }
  }, [isAuthenticated, isLoading]);

  const commonRoutes = [
    { path: '/', element: <Landing /> },
    { path: '/verify-email', element: <ThankYou location={undefined} /> },
    { path: '/resetpassword', element: <ResetPassword /> },
    { path: '/on-boarding/portfolio/create', element: <PortfolioLanding /> },
    { path: '/accept-terms-and-conditions', element: <Terms /> },
    { path: '/get-started', element: <GetStarted /> },
    { path: '/privacy-policy', element: <PrivacyPolicy /> },
    { path: '/terms-of-use', element: <TermsOfUse /> },
    { path: '/referral', element: <Referral /> },
    { path: '/license', element: <License /> },
    { path: '*', element: <NotFound /> },
  ];

  // Always call useRoutes unconditionally
  const routes = isAuthenticated
    ? [...protectedRoutes, ...commonRoutes]
    : [...publicRoutes, ...commonRoutes];

  const element = useRoutes(routes);

  // First, handle the authentication loading state
  if (isLoading) {
    setTimeout(() => {
      if (isLoading) {
        window.location.reload();
      }
    }, 1000);
    return <FullScreenLoader />;
  }

  // Then handle the route rendering with proper error boundaries and suspense
  return (
    <ErrorBoundary
      fallback={<NotFound />}
      onError={error => {
        console.error('Route Error:', error);
      }}
    >
      <Suspense fallback={<FullScreenLoader />}>{element}</Suspense>
    </ErrorBoundary>
  );
};
