// @ts-nocheck
import { AuthLayout } from 'features/auth/components/AuthLayout'
import { Link } from 'react-router-dom'

export const Terms = () => {
  return (
    <AuthLayout>
      <div className="mx-8">
        <h1 className="my-12 text-2xl font-bold">Welcome Jonathan</h1>
        <p className="my-2">
          Before we get started, can we send you email sand do you agree with
          tickerzone’s terms?
        </p>
        <form className="my-4 space-y-6">
          <label className="flex space-x-4">
            <input
              type="checkbox"
              className="mr-4 h-6 w-6 rounded-md bg-green-400"
            />
            I have read and accept the terms of service and privacy policy
          </label>
          <label className="flex space-x-4">
            <input
              type="checkbox"
              className="checked mr-4 h-6 w-6 rounded-md text-white accent-green-400"
            />
            I want to receive news about Tickerzone by email
          </label>
          <div className="w-full py-6">
            <Link
              to="/auth/create-portfolio"
              className="w-full rounded-md bg-flamingo-500 p-3 px-12 text-white hover:bg-flamingo-600"
            >
              <span>Accept</span>
            </Link>
          </div>
        </form>
      </div>
    </AuthLayout>
  )
}
