import { lazyImport } from 'utils/lazyImport';
import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { FullScreenLoader } from 'components/elements';

const WatchlistRoutes = lazyImport(
  () => import('features/watchlist'),
  'WatchlistRoutes'
);
const OverviewRoutes = lazyImport(
  () => import('features/overview'),
  'OverviewRoutes'
);
const SupportRoutes = lazyImport(
  () => import('features/support'),
  'SupportRoutes'
);
const SettingsRoutes = lazyImport(
  () => import('features/settings'),
  'SettingsRoutes'
);
const SearchRoutes = lazyImport(
  () => import('features/search'),
  'SearchRoutes'
);
const PortfoliosRoutes = lazyImport(
  () => import('features/portfolios'),
  'PortfoliosRoutes'
);
const MarketsRoutes = lazyImport(
  () => import('features/markets'),
  'MarketsRoutes'
);
const DiscoverRoutes = lazyImport(
  () => import('features/discover'),
  'DiscoverRoutes'
);
const NotificationsRoutes = lazyImport(
  () => import('features/notifications'),
  'NotificationsRoutes'
);
const AccountRoutes = lazyImport(
  () => import('features/account'),
  'AccountRoutes'
);

const AccountSettingsRoutes = lazyImport(
  () => import('features/account_settings'),
  'AccountSettingsRoutes'
);

const TradelogsRoutes = lazyImport(
  () => import('features/tradelogs'),
  'TradelogsRoutes'
);

const SubscriptionRoutes = lazyImport(
  () => import('features/payments'),
  'SubscriptionRoutes'
);

const App = () => {
  return (
    <>
      <Suspense fallback={<FullScreenLoader />}>
        <Outlet />
      </Suspense>
    </>
  );
};

export const protectedRoutes = [
  {
    path: '/app/*',
    element: <App />,
    children: [
      { path: 'discover/*', element: <DiscoverRoutes /> },
      { path: 'markets/*', element: <MarketsRoutes /> },
      { path: 'portfolios/*', element: <PortfoliosRoutes /> },
      { path: 'search/*', element: <SearchRoutes /> },
      { path: 'settings/*', element: <SettingsRoutes /> },
      { path: 'support/*', element: <SupportRoutes /> },
      { path: 'account/*', element: <AccountRoutes /> },
      { path: 'account_settings/*', element: <AccountSettingsRoutes /> },
      { path: 'trades/logs/*', element: <TradelogsRoutes /> },
      { path: 'watchlist/*', element: <WatchlistRoutes /> },
      { path: 'notifications/*', element: <NotificationsRoutes /> },
      { path: 'payments/*', element: <SubscriptionRoutes /> },
      { path: '', element: <OverviewRoutes /> }, // Default child route
    ],
  },
];
