// ColorModeContext.js
import { useDarkMode } from 'hooks/useDarkMode';
import React, { createContext, useContext, useEffect } from 'react';

const ColorModeContext = createContext('');

export const useColorModeContext = () => useContext(ColorModeContext);

export const ColorModeProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const colorModeHook = useDarkMode();

  useEffect(() => {
    // @ts-ignore
    if (colorModeHook.isDarkMode) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
    // @ts-ignore
  }, [colorModeHook.isDarkMode]);

  return (
    // @ts-ignore
    <ColorModeContext.Provider value={colorModeHook}>
      {children}
    </ColorModeContext.Provider>
  );
};
