import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ContentLayout } from 'components/layout';
import { FiSearch } from 'react-icons/fi';
import clsx from 'clsx';

export const Search = () => {
  const [q, setQ] = useState('');
  const navigate = useNavigate();

  const handleInputChange = e => {
    setQ(e.target.value);
  };

  const handleSearch = () => {
    if (q.length === 0) {
      navigate(`/app`);
      return (
        <ContentLayout
          page="search"
          headerContentLeft={
            <div className="text-2xl font-semibold md:font-semibold">
              Search Results
            </div>
          }
        >
          <div className="bg-accent-500 shadow hover:shadow-lg py-3 rounded-md px-10">
            <div className="flex items-center gap-3 font-bold">
              No search query
            </div>
            <div>Please enter a search query to get results.</div>
          </div>
        </ContentLayout>
      );
    }
    // Navigate to the results page with the search query as a URL parameter
    const prompt = q.toLowerCase(); // Convert q to uppercase
    if (prompt.length === 0) navigate(`/app/`);
    if (!prompt) navigate(`/app`);
    navigate(`/app/search/tickers/${encodeURIComponent(prompt)}`);
  };

  return (
    <form className="max-w-md w-full">
      <label
        htmlFor="search"
        className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
      >
        Search
      </label>
      <div className="relative">
        <div className="absolute inset-y-0 left-0 pl-4 flex items-center pointer-events-none">
          <FiSearch
            className="h-4 w-4 text-gray-400 dark:text-gray-500"
            aria-hidden="true"
          />
        </div>
        <input
          value={q}
          onChange={handleInputChange}
          type="search"
          id="search"
          className={clsx(
            'block w-full pl-10 pr-16', // Adjusted padding for icon and button
            'py-2.5 text-sm rounded-xl', // Slightly larger, rounded corners
            'bg-gray-50 dark:bg-gray-800', // Lighter/darker backgrounds
            'border border-gray-200 dark:border-gray-700', // Subtle borders
            'text-gray-900 dark:text-gray-100',
            'placeholder-gray-500 dark:placeholder-gray-400',
            'focus:outline-none focus:ring-2 focus:ring-blue-500/50 focus:border-blue-500', // Professional blue focus
            'dark:focus:ring-blue-500/40 dark:focus:border-blue-500',
            'transition-all duration-200', // Smooth transitions
            'hover:border-gray-300 dark:hover:border-gray-600' // Hover effect
          )}
          placeholder="Search tickers..."
          required
        />
        <div className="absolute inset-y-0 right-0 flex items-center pr-1.5">
          <button
            onClick={handleSearch}
            className={clsx(
              'inline-flex items-center px-3 py-1.5 mr-1',
              'text-sm font-medium rounded-lg',
              'bg-blue-600 text-white', // Professional blue
              'hover:bg-blue-700',
              'focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-1',
              'dark:bg-blue-500 dark:hover:bg-blue-600',
              'transition-colors duration-200',
              'disabled:opacity-50 disabled:cursor-not-allowed'
            )}
            type="submit"
          >
            Search
          </button>
        </div>
      </div>

      {/* Optional: Add search suggestions dropdown */}
      {q.length > 0 && (
        <div className="absolute mt-1 w-full rounded-lg bg-white dark:bg-gray-800 shadow-lg border border-gray-200 dark:border-gray-700 z-50">
          {/* Add your search suggestions here */}
        </div>
      )}
    </form>
  );
};
