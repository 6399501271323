import { axios } from 'lib/axios'
import { useQuery } from 'react-query'

export const getPortfolios = (ticker_type = 'stock') => {
  if (!ticker_type) {
    throw new Error('ticker_type is required')
  }
  return axios.get('/tapp/portfolio/active_list/', {
    params: { ticker_type },
  })
}

export const usePortfolios = (
  { ticker_type = 'stock', config = {} } = {} as any
) => {
  return useQuery({
    ...config,
    queryKey: ['portfolios', ticker_type],
    queryFn: () => getPortfolios(ticker_type),
    // Don't run query if ticker_type is not provided
    enabled: !!ticker_type && config?.enabled !== false,
    // Add error handling
    onError: error => {
      console.error('Failed to fetch portfolios:', error)
    },
  })
}
