//@ts-nocheck
import React from 'react';
import { useAddPosition } from '../api/addPosition';
import { usePortfolios } from '../api/getPortfolios';
import { useTickers } from '../api/getTickers';
import {
  Form,
  FormDrawer,
  InputField,
  ReactSelectField,
} from 'components/form';
import { Button } from 'components/elements';
import { useState, useMemo } from 'react';
import { InputQuantity } from 'components/form/InputQuantity';

export const AddPosition = ({
  triggerButton,
  ticker,
}: {
  triggerButton: React.ReactNode;
  ticker?: string;
}) => {
  const addPositionMutation = useAddPosition();
  const portfoliosQuery = usePortfolios();
  const [searchInput, setSearchInput] = useState('');

  const tickersQuery = useTickers(searchInput);
  const resultTickers = useMemo(() => {
    // Check if the searchInput has at least 1 character before filtering
    if (searchInput.length < 1) {
      return [];
    }
    return tickersQuery.data;
  }, [tickersQuery, searchInput]);

  if (!portfoliosQuery.data) return null;

  return (
    <>
      <FormDrawer
        size="sm"
        isDone={addPositionMutation.isSuccess}
        triggerButton={triggerButton}
        title={`${ticker ? `Add position for ${ticker}` : 'Add new position'}`}
        submitButton={
          <Button
            form="add-position"
            type="submit"
            size="sm"
            isLoading={addPositionMutation.isLoading}
          >
            Submit
          </Button>
        }
      >
        <Form
          id="add-position"
          onSubmit={async (values: any) => {
            if (ticker) {
              values.ticker = ticker;
            }
            await addPositionMutation.mutateAsync({ data: values });
          }}
          // schema={schema}
        >
          {({
            register,
            formState,
            watch,
            setValue,
          }: {
            register: any;
            formState: any;
            watch: any;
            setValue: any;
          }) => (
            <>
              {ticker ? null : (
                <ReactSelectField
                  label="Search for ticker"
                  editorContent={watch('ticker')}
                  setValue={setValue}
                  name="ticker"
                  required={true}
                  defaultValue={watch('ticker')}
                  error={formState.errors['ticker']}
                  options={
                    Array.isArray(resultTickers)
                      ? resultTickers.map(p => ({
                          label: `${p.ticker} - ${p.company_name}`,
                          value: p.ticker,
                        }))
                      : [] // Provide a default empty array if filteredOptions is not an array
                  }
                  // Pass the setSearchInput function as onInputChange prop
                  onInputChange={inputValue => setSearchInput(inputValue)}
                />
              )}

              <InputQuantity
                label="Quantity"
                type="number"
                step={0.01}
                error={formState.errors['quantity']}
                required={true}
                registration={register('quantity')}
              />
              <InputField
                label="Entry Price"
                type="number"
                step={0.001}
                required={true}
                error={formState.errors['entry_price']}
                registration={register('entry_price')}
              />
              <InputField
                label="Price Target"
                type="number"
                step={0.001}
                required={true}
                error={formState.errors['price_target']}
                registration={register('price_target')}
              />
              <ReactSelectField
                label="Select Portfolio"
                editorContent={watch('portfolio_pk')}
                setValue={setValue}
                name="portfolio_pk"
                required={true}
                defaultValue={watch('portfolio_pk')}
                error={formState.errors['portfolio_pk']}
                options={portfoliosQuery.data?.map(p => ({
                  label: p.portfolio_name,
                  value: p.portfolio_id,
                }))}
              />
            </>
          )}
        </Form>
      </FormDrawer>
    </>
  );
};
