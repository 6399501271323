import { ColorSwitch } from './ColorSwitch';
export const SidebarFooter = () => {
  return (
    <div>
      <div className="flex flex-col space-y-2 justify-center">
        <ColorSwitch />
      </div>
    </div>
  );
};
