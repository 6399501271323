// @ts-nocheck
import { Link } from 'react-router-dom'
import logo from 'assets/logo-dark.svg'
import darklogo from 'assets/logo.png'
import { useAuth } from 'lib/auth'
import { useColorModeContext } from 'context/ColorContext'

export const NotFound = () => {
  const { isAuthenticated } = useAuth()
  const darkMode = useColorModeContext()
  return (
    <div className={` ${darkMode[0] ? `dark` : ``}`}>
      <div className="min-h-screen dark:bg-black dark:text-white">
        <div className="mx-8 flex items-center justify-between py-8">
          <Link to={isAuthenticated ? '/app' : '/'}>
            <img
              src={darkMode[0] ? darklogo : logo}
              alt="logo"
              className="h-8 w-auto"
            />
          </Link>
        </div>
        <div className="my-48 flex w-full flex-col items-center justify-center space-y-4">
          <p className="text-3xl font-bold">404</p>
          <p className="text-xl font-bold">Page doesn't exist</p>
          <Link
            to={isAuthenticated ? '/app' : '/'}
            className="rounded-md bg-flamingo-500 px-4 py-2 text-white hover:bg-flamingo-600"
          >
            Go Back Home
          </Link>
        </div>
      </div>
    </div>
  )
}
