// @ts-nocheck
import {
  Dialog,
  DialogDescription,
  DialogTitle,
  FullScreenLoader,
} from 'components/elements'
import { Notifications } from 'components/Notifications/Notifications'
import { AuthProvider } from 'lib/auth'
import { queryClient } from 'lib/react-query'
import * as React from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { HelmetProvider } from 'react-helmet-async'
import { QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { BrowserRouter as Router } from 'react-router-dom'
import storage from 'utils/storage'
import logo from 'assets/logo-dark.svg'
import darklogo from 'assets/logo.svg'
import { useColorModeContext } from 'context/ColorContext'
// import { useEffect } from 'react';
// import { refreshToken } from 'features/auth';

const ErrorFallback = ({ error }: { error: Error }) => {
  const handleRefresh = () => {
    storage.clearStorage()
    window.location.assign('/')
  }

  const [showErrorModal, setShowErrorModal] = React.useState(false)

  const handleCloseErrorModal = () => {
    setShowErrorModal(false)
  }

  const darkMode = useColorModeContext()

  return (
    <div className={darkMode[0] ? `dark` : ``}>
      <div className="min-h-screen dark:bg-black dark:text-white">
        <div className="mx-8 flex flex-col items-start space-y-4 pt-64 md:mx-64">
          <img
            src={darkMode[0] ? darklogo : logo}
            alt="tickerzone logo"
            className="h-4"
          />
          <div className="text-flamingo-500">Something went wrong</div>
          <div className="text-gray-800 dark:text-white">
            Please try the recommended action below
          </div>
          <div className="flex flex-col space-y-2 md:flex-row md:space-x-2">
            <button
              onClick={handleRefresh}
              className="w-64 bg-flamingo-500 p-2 px-4 text-white"
            >
              Refresh Application
            </button>
          </div>
          {/* <button
        onClick={handleShowErrorModal}
        className="border bg-white text-xs border-flamingo-500 p-2 px-4 w-64 text-flamingo-500"
      >
        Display Error Message
      </button> */}
          <Dialog
            isOpen={showErrorModal}
            onClose={handleCloseErrorModal}
            //@ts-ignore
            initialFocus={null}
          >
            <div className="bg-white p-8 shadow-md">
              <DialogTitle className="mb-4 text-xl font-bold">
                {storage.getErrorMessage()}
              </DialogTitle>
              <DialogDescription>
                <pre className="max-h-64 overflow-auto">
                  {JSON.stringify(storage.getErrorResponse(), null, 2)}
                </pre>
              </DialogDescription>
              <button
                onClick={handleCloseErrorModal}
                className="mt-4 bg-flamingo-500 p-2 px-4 text-white"
              >
                Close
              </button>
            </div>
          </Dialog>
        </div>
      </div>
    </div>
  )
}

export const AppProvider = ({ children }: { children: React.ReactNode }) => {
  return (
    <React.Suspense fallback={<FullScreenLoader />}>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <HelmetProvider>
          <QueryClientProvider client={queryClient}>
            {process.env.NODE_ENV !== 'test' && <ReactQueryDevtools />}
            <Notifications />
            <AuthProvider>
              <Router>{children}</Router>
            </AuthProvider>
          </QueryClientProvider>
        </HelmetProvider>
      </ErrorBoundary>
    </React.Suspense>
  )
}
