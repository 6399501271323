import { Navigate, Route, Routes } from 'react-router-dom'
import { Signin } from './Signin'
import { CreateAccount } from './CreateAccount'
import { ResetPasswordInfo } from './ResetPasswordConfirmation'
import { ForgotPassword } from './ForgotPassword'
import { ActivateAccount } from './ActivateAccount'
import { ResetPassword } from './ResetPassword'
import { ResetPasswordSuccess } from './PasswordResetSuccess'

export const AuthRoutes = () => {
  return (
    <Routes>
      <Route path="" element={<Signin />} />
      <Route path="/create-new-account" element={<CreateAccount />} />
      <Route
        path="/create-new-account/thank-you"
        element={<ActivateAccount />}
      />
      <Route path="/reset-password-info" element={<ResetPasswordInfo />} />
      <Route
        path="/reset-password-success"
        element={<ResetPasswordSuccess />}
      />
      <Route path="/resetpassword" element={<ResetPassword />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="*" element={<Navigate to="." />} />
    </Routes>
  )
}
