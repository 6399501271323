// @ts-nocheck
import { ContentLayout } from 'components/layout';
import FreePikStoriesLeader from 'assets/freepik_stories-leader.svg';
import { Button } from 'components/elements';
import { useNavigate, useLocation } from 'react-router-dom';

export const CreatePortfolioFailure = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const errorMessage =
    location.state?.errorMessage || 'An error occurred. Please try again.';

  const handleCreatePortfolio = async () => {
    navigate('/app/portfolios/create');
  };

  return (
    <ContentLayout>
      <div className="flex flex-col text-center justify-center items-center space-y-2">
        <img src={FreePikStoriesLeader} alt="Failure" className="h-48" />
        <h3>Linking your account was unsuccessful.</h3>
        <p>{errorMessage}</p>
        <Button
          onClick={handleCreatePortfolio}
          className="w-64 text-xs py-4 whitespace-nowrap"
        >
          Create Portfolio
        </Button>
      </div>
    </ContentLayout>
  );
};
