// @ts-nocheck
import { axios } from 'lib/axios';
import { queryClient } from 'lib/react-query';
import { useNotificationStore } from 'stores/notifications';
import { useMutation } from 'react-query';

export const addOptionPosition = ({ data }: AddOptionPosition) => {
  return axios.post(`/tapp/options/enter_position`, data);
};

type AddOptionPosition = {
  data: {
    ticker: string;
    quantity: number;
    strike_price: number;
    expiration_date: string;
    option_type: string;
    entry_price: number;
    price_target: number;
    portfolio_pk: string;
  };
};

export const useAddOptionPosition = ({ config = {} } = {}) => {
  const { addNotification } = useNotificationStore();
  return useMutation({
    onMutate: async newPosition => {
      await queryClient.cancelQueries('activeTrades');

      const previousTradePositions = queryClient.getQueryData('activeTrades');

      queryClient.setQueryData('activeTrades', [
        // @ts-ignore
        ...(previousTradePositions || []),
        newPosition.data,
      ]);

      return { previousTradePositions };
    },
    onError: (_, __, context) => {
      if (context?.previousTradePositions) {
        queryClient.setQueryData(
          'activeTrades',
          context.previousTradePositions
        );
      }
    },
    onSuccess: data => {
      queryClient.invalidateQueries('activeTrades');
      queryClient.invalidateQueries('portfolioSummary');
      addNotification({
        type: 'success',
        // @ts-ignore
        title: data.detail,
      });
    },
    ...config,
    mutationFn: addOptionPosition,
  });
};
