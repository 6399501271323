// @ts-nocheck
import { Link } from 'react-router-dom'
import { Heading } from 'components/layout/components/Heading'
import abstract from 'assets/abstract.png'

export const Solutions = () => {
  return (
    <div className="grid grid-cols-12 gap-6 bg-indigo-500 dark:bg-dim-300">
      <div className="hidden w-full md:col-span-4 md:flex">
        <img src={abstract} alt="abstract" className="w-auto" />
      </div>
      <div className="col-span-12 mx-auto flex w-5/6 flex-col items-center justify-center pb-16 pt-8 md:col-span-8">
        <Heading color="white">Solutions to empower every investor</Heading>
        <div className="mx-auto my-8 w-full space-y-8 md:w-4/6">
          <div className="flex items-start space-x-4 text-white">
            <div className="flex items-center">
              <div className="h-fit rounded bg-gray-600 px-4 py-2">
                <p className="text-xl font-bold text-white">1</p>
              </div>
              <div className="h-1 w-12 bg-gray-600"></div>
            </div>
            <div className="space-y-2">
              <h4 className="my-0">Invest with confidence</h4>
              <p>
                Target setting helps you refine your investment thesis and
                allows you to evaluate positions with data-driven insights.
              </p>
            </div>
          </div>
          <div className="flex items-start space-x-4 text-white">
            <div className="flex items-center">
              <div className="h-fit rounded bg-gray-600 px-4 py-2">
                <p className="text-xl font-bold text-white">2</p>
              </div>
              <div className="h-1 w-12 bg-gray-600"></div>
            </div>
            <div className="space-y-2">
              <h4 className="my-0">
                Have a clear view of your portfolio at your fingertips
              </h4>
              <p>
                Tickerzone provides a simple overview of all your investments in
                a single dashboard.
              </p>
            </div>
          </div>
          <div className="flex items-start space-x-4 text-white">
            <div className="flex items-center">
              <div className="h-fit rounded bg-gray-600 px-4 py-2">
                <p className="text-xl font-bold text-white">3</p>
              </div>
              <div className="h-1 w-12 bg-gray-600"></div>
            </div>
            <div className="space-y-2">
              <h4 className="my-0">
                Affordable insights and transparent pricing
              </h4>
              <p>
                Our affordable and intuitive platform puts actionable insights
                at your fingertips, so you can make informed decisions without
                breaking the bank.
              </p>
            </div>
          </div>
          <div>
            <Link
              to="/auth/create-new-account/"
              className="rounded-md bg-flamingo-500 px-3 py-2 text-xs font-medium uppercase text-white"
            >
              Get Started
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}
