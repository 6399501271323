import { zodResolver } from '@hookform/resolvers/zod';
import clsx from 'clsx';
import { useForm } from 'react-hook-form';
import React from 'react';

export const Form = ({
  onSubmit,
  children,
  className,
  options,
  id,
  schema,
}: {
  onSubmit: any;
  children: any;
  className?: string;
  options?: any;
  id?: string;
  schema?: any;
}) => {
  const methods = useForm({
    ...options,
    resolver: schema && zodResolver(schema),
  });
  return (
    <form
      className={clsx('space-y-6', className)}
      onSubmit={methods.handleSubmit(onSubmit)}
      id={id}
    >
      {children(methods)}
    </form>
  );
};
