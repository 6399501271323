// @ts-nocheck
import { Link, useLocation } from 'react-router-dom';
import {
  FiActivity,
  FiGrid,
  FiList,
  FiPieChart,
  FiSettings,
  FiCreditCard,
  FiHelpCircle,
} from 'react-icons/fi';
import { useAuth } from 'lib/auth';
import { useColorModeContext } from 'context/ColorContext';
import { SidebarFooter } from './SidebarFooter';
import clsx from 'clsx';
import logo from 'assets/logo-dark.svg';
import darklogo from 'assets/logo.svg';

const navigation = [
  { name: 'Overview', icon: FiGrid, href: '/app', match: 'overview' },
  {
    name: 'Holdings',
    icon: FiPieChart,
    href: '/app/portfolios',
    match: 'portfolios',
  },
  {
    name: 'Watchlist',
    icon: FiList,
    href: '/app/watchlist',
    match: 'watchlist',
  },
  {
    name: 'Trade Logs',
    icon: FiActivity,
    href: '/app/trades/logs',
    match: 'trades/logs',
  },
];

export const SideBar = ({ page, sidebarOpen, setSidebarOpen }) => {
  const { isAuthenticated, user } = useAuth();
  const [isDarkMode] = useColorModeContext();
  const location = useLocation();

  const secondaryNavigation = [
    ...(!user?.subscription_status || user?.subscription_status !== 'active'
      ? [
          {
            name: 'Subscribe',
            icon: FiCreditCard,
            href: '/app/payments/subscription-plans',
            match: 'payments/subscription-plans',
            highlight: true,
          },
        ]
      : []),
    {
      name: 'Settings',
      icon: FiSettings,
      href: '/app/account_settings',
      match: 'account_settings',
    },
    {
      name: 'Support',
      icon: FiHelpCircle,
      href: '/app/support',
      match: 'support',
    },
  ];

  const NavItem = ({ item, isSecondary = false }) => {
    // Update the active state check to be more specific
    const isActive = isSecondary
      ? location.pathname === item.href // Exact match for secondary items
      : location.pathname === item.href || page === item.match;

    return (
      <Link
        to={item.href}
        className={clsx(
          'flex items-center px-3 py-2 rounded-lg text-sm font-medium',
          'transition-all duration-200',
          'hover:bg-gray-100 dark:hover:bg-gray-700/50',
          isActive
            ? 'text-blue-600 dark:text-blue-400 bg-blue-50 dark:bg-blue-900/20'
            : item.highlight // Add special styling for highlighted items
            ? 'text-blue-600 dark:text-blue-400 bg-blue-50/50 dark:bg-blue-900/10'
            : isSecondary
            ? 'text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-300'
            : 'text-gray-700 dark:text-gray-300'
        )}
      >
        <item.icon
          className={clsx(
            'mr-3 h-5 w-5 flex-shrink-0',
            isActive || item.highlight
              ? 'text-blue-600 dark:text-blue-400'
              : 'text-gray-400 dark:text-gray-500'
          )}
        />
        <span className="truncate">{item.name}</span>
      </Link>
    );
  };

  return (
    <div
      className={clsx(
        'flex h-full flex-col border-r border-gray-200 dark:border-gray-700 bg-white dark:bg-gray-800',
        'transition-all duration-300',
        sidebarOpen ? 'w-64' : 'w-20'
      )}
    >
      {/* Logo and collapse button */}
      <div className="flex items-center justify-between px-4 py-6">
        <Link to={isAuthenticated ? '/app' : '/'} className="flex items-center">
          <img
            src={isDarkMode ? darklogo : logo}
            alt="Logo"
            className={clsx(
              'transition-all duration-300',
              sidebarOpen ? 'w-32' : 'w-8'
            )}
          />
        </Link>
        <button
          onClick={() => setSidebarOpen(!sidebarOpen)}
          className="p-1.5 rounded-lg text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700"
        >
          <svg
            className="h-5 w-5"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d={sidebarOpen ? 'M15 19l-7-7 7-7' : 'M9 5l7 7-7 7'}
            />
          </svg>
        </button>
      </div>

      {/* Navigation */}
      <div className="flex-1 flex flex-col px-3 py-4 space-y-1">
        <nav className="flex-1 space-y-1">
          {navigation.map(item => (
            <NavItem key={item.name} item={item} />
          ))}
        </nav>

        {/* Secondary Navigation */}
        <div className="pt-4 mt-4 border-t border-gray-200 dark:border-gray-700 space-y-1">
          {secondaryNavigation.map(item => (
            <NavItem key={item.name} item={item} isSecondary />
          ))}
        </div>
      </div>

      {/* Footer */}
      <div className="p-4 border-t border-gray-200 dark:border-gray-700">
        <div
          className={clsx(
            'flex items-center',
            sidebarOpen ? 'justify-between' : 'justify-center'
          )}
        >
          {sidebarOpen && <SidebarFooter />}
        </div>
      </div>
    </div>
  );
};
