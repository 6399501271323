// @ts-nocheck
import { Button, Dialog, DialogTitle, Spinner } from 'components/elements'
import { Form, InputField, SelectField } from 'components/form'
import { useColorModeContext } from 'context/ColorContext'
import { useState } from 'react'
import { useScheduleADemo } from '../api/scheduleADemo'
import * as z from 'zod'
import { useNavigate } from 'react-router-dom'

const schema = z.object({
  firstName: z.string().min(1, 'Required'),
  lastName: z.string().min(1, 'Required'),
  email: z.string().min(1, 'Required'),
  phoneNumber: z.string().min(1, 'Required'),
  industry: z.string().min(1, 'Required'),
  hearAboutUs: z.string().min(1, 'Required'),
})

export const ScheduleADemo = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const scheduleADemoMutation = useScheduleADemo()

  const navigate = useNavigate()
  const darkMode = useColorModeContext()

  if (scheduleADemoMutation.isLoading) {
    return (
      <div
        id="loading-screen"
        className="fixed left-0 top-0 z-50 block h-full w-full bg-black opacity-85"
      >
        <span className="relative top-1/2 mx-auto my-0 block h-0 w-0 opacity-75">
          <Spinner size="md" />
        </span>
      </div>
    )
  }

  const handleOpenModal = () => {
    setIsModalOpen(true)
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  return (
    <div>
      <Button
        onClick={handleOpenModal}
        size="sm"
        className="mb-2 whitespace-nowrap"
      >
        Schedule Meeting
      </Button>
      <Dialog
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        initialFocus={null}
      >
        <div
          className={`${
            darkMode[0] ? `bg-dim-300` : `bg-white`
          } sm:z-100 inline-block transform overflow-hidden rounded-lg px-4 pb-4 pt-5 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 sm:align-middle`}
        >
          <DialogTitle className="text-lg font-semibold">
            Schedule a Demo
          </DialogTitle>

          <div>
            <Form
              id="schedule-a-demo"
              onSubmit={async values => {
                await scheduleADemoMutation.mutateAsync({ data: values })
                navigate('./thank-you')
              }}
              schema={schema}
            >
              {({ register, formState, watch, setValue }) => (
                <>
                  <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
                    <InputField
                      label="First Name"
                      placeholder="First Name"
                      error={formState.errors['firstName']}
                      registration={register('firstName')}
                    />
                    <InputField
                      label="Last Name"
                      placeholder="Last Name"
                      error={formState.errors['lastName']}
                      registration={register('lastName')}
                    />
                  </div>
                  <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
                    <InputField
                      label="Email Address"
                      placeholder="Email Address"
                      error={formState.errors['email']}
                      registration={register('email')}
                    />
                    <InputField
                      label="Phone Number"
                      placeholder="Phone Number"
                      error={formState.errors['phoneNumber']}
                      registration={register('phoneNumber')}
                    />
                  </div>
                  <SelectField
                    label="Industry"
                    editorContent={watch('industry')}
                    setValue={setValue}
                    required={true}
                    name="industry"
                    defaultValue={watch('industry')}
                    error={formState.errors['industry']}
                    options={[
                      { value: 'tech', label: 'Technology' },
                      { value: 'finance', label: 'Finance' },
                      { value: 'healthcare', label: 'Healthcare' },
                      // Add more industry options as needed
                    ]}
                  />
                  <SelectField
                    label="How did you hear about us?"
                    editorContent={watch('hearAboutUs')}
                    setValue={setValue}
                    required={true}
                    name="hearAboutUs"
                    defaultValue={watch('hearAboutUs')}
                    error={formState.errors['hearAboutUs']}
                    options={[
                      { value: 'online', label: 'Online Advertisements' },
                      { value: 'referral', label: 'Referral' },
                      { value: 'social-media', label: 'Social Media' },
                      // Add more options as needed
                    ]}
                  />
                  <Button
                    form="schedule-a-demo"
                    type="submit"
                    size="sm"
                    className="w-full"
                    isLoading={scheduleADemoMutation.isLoading}
                  >
                    send message
                  </Button>
                </>
              )}
            </Form>
          </div>
        </div>
      </Dialog>
    </div>
  )
}
