import { Button } from 'components/elements'
import { activateAccount } from 'features/auth/api/activate_account'
import { AuthLayout } from 'features/auth/components/AuthLayout'
import React, { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

export const ThankYou = ({ location }) => {
  const navigate = useNavigate()
  const [params, _] = useSearchParams()
  const username = params.get('username')
  const confirmation_token = params.get('confirmation_token')

  useEffect(() => {
    // Here, you can send the extracted username and confirmation_token to the server
    // for account activation. You can make an API call or perform the necessary logic.
    if (username && confirmation_token) {
      activateAccount({ username, confirmation_token })
    }
  }, [username, confirmation_token])
  return (
    <AuthLayout option="login">
      <div className="">
        <h1 className="my-12 text-4xl font-bold">Account Confirmation.</h1>
        <div className="my-12 mr-32 text-xl font-normal">
          Thank you for confirming your email. Please proceed to login.
        </div>
        <Button onClick={() => navigate('/auth')}>Sign in</Button>
      </div>
    </AuthLayout>
  )
}
