import { Heading } from 'components/layout/components/Heading'

export const Features = () => {
  return (
    <div className="bg-flamingo-500 px-8">
      <div className="mx-auto w-full py-8 lg:w-5/6">
        <Heading color="white">Simplifying Financial Wellness</Heading>
        <div className="mx-auto my-8 w-full space-y-8 md:w-4/6">
          <div className="flex items-start space-x-4 space-y-0 text-white">
            <div className="flex items-center">
              <div className="rounded bg-white px-4 py-2">
                <p className="text-xl font-bold text-flamingo-500">1</p>
              </div>
              <div className="h-1 w-12 bg-white"></div>
            </div>
            <div className="space-y-2">
              <h3 className="my-0">Accessible to all</h3>
              <p>
                We believe in giving everyone access to the best financial tools
                and empowering investors at every level.
              </p>
            </div>
          </div>
          <div className="flex items-start space-x-4 text-white">
            <div className="flex items-center">
              <div className="rounded bg-white px-4 py-2">
                <p className="text-xl font-bold text-flamingo-500">2</p>
              </div>
              <div className="h-1 w-12 bg-white"></div>
            </div>
            <div className="space-y-2">
              <h3 className="my-0">Empowering Your Financial Journey</h3>
              <p>
                Designed to help investors focus on long term goals and reduce
                the complexity behind financial planning.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
