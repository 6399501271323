import clsx from 'clsx';

import React from 'react';
import { FieldWrapper } from './FieldWrapper';
import { useColorModeContext } from 'context/ColorContext';

interface InputFieldProps {
  type?: 'text' | 'number' | 'password' | 'email';
  label?: string;
  className?: string;
  maxLength?: number;
  caption?: string;
  step?: number;
  defaultValue?: string | number;
  registration?: any;
  error?: string;
  required?: boolean;
}

export const InputField: React.FC<InputFieldProps> = props => {
  const {
    type = 'text',
    label,
    className,
    maxLength,
    caption,
    step,
    defaultValue,
    registration,
    error,
    required,
  } = props;

  const darkMode = useColorModeContext();
  // limit image dimensions to 50x50

  const handleInput = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;
    if (type === 'number' && value.startsWith('-')) {
      // Prevent negative values for input of type "number"
      event.target.value = value.replace(/-/g, ''); // Remove all '-' characters
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;
    if (type === 'number' && value.startsWith('-')) {
      // Prevent negative values for input of type "number"
      event.target.value = value.replace(/-/g, ''); // Remove all '-' characters
    }
  };

  return (
    <FieldWrapper label={label} error={error}>
      <div className="text-xs my-1 text-gray-400">{caption}</div>
      <input
        type={type}
        step={step}
        maxLength={maxLength}
        defaultValue={defaultValue}
        required={required}
        className={clsx(
          `border border-solid ${error && `border-red-500`} ${
            darkMode[0] ? `bg-dim-100` : `bg-gray-100`
          } border-gray-100 rounded-md w-full outline-none 
          focus:outline-none px-8 py-3 dark:bg-gray-900`,
          className
        )}
        onInput={handleInput} // Handle input to block negative values
        onChange={handleChange}
        {...registration}
      />
    </FieldWrapper>
  );
};
