import { AuthLayout } from '../components/AuthLayout'

export const ResetPasswordInfo = () => {
  return (
    <AuthLayout option="reset-password-info">
      <div className="my-32 text-center">
        <h1 className="mb-4 text-3xl font-bold md:text-4xl">Success!</h1>
        <p className="mb-8 text-lg md:text-xl">
          Please check your email for a password-reset link.
        </p>
      </div>
    </AuthLayout>
  )
}
