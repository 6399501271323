import { Button, ConfirmationDialog } from 'components/elements';

import { useDeleteActiveTrade } from '../api/deleteActiveTrade';
import { useColorModeContext } from 'context/ColorContext';

import './../assets/frameclick.css';

export const DeleteActiveTrade = ({ ticker_type, tradeId, ticker } : { ticker_type: string; tradeId: string; ticker: string }) => {
  const deleteActiveTradeMutation = useDeleteActiveTrade();
  const darkMode = useColorModeContext();
  const colorScheme = darkMode[0] ? 'dark' : 'light';

  return (
    <>
      <ConfirmationDialog
        icon="danger"
        title="Delete Active Trade"
        body={`Are you sure you want to remove ${ticker} from holdings?`}
        triggerButton={
          <button
            className={`trigger-button flex items-center justify-center m-2 mx-4 cursor-pointer ${colorScheme}`}
          >
            <span>Remove</span>
          </button>
        }
        confirmButton={
          <Button
            isLoading={deleteActiveTradeMutation.isLoading}
            type="button"
            className="bg-red-600"
            onClick={async () =>
              await deleteActiveTradeMutation.mutateAsync({ ticker_type, tradeId })
            }
          >
            Delete Trade
          </Button>
        }
      />
    </>
  );
};
