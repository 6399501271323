import { useDisclosure } from 'hooks/useDisclosure';
import * as React from 'react';

import { Button } from '../elements/button';
import { Drawer } from '../elements/drawer';
import { useColorModeContext } from 'context/ColorContext';

export const FormDrawer = ({
  title,
  children,
  isDone,
  triggerButton,
  submitButton,
  size = 'md',
}: {
  title: string;
  children: React.ReactNode;
  isDone?: boolean;
  triggerButton: React.ReactNode;
  submitButton: React.ReactNode;
  size?: 'sm' | 'md' | 'lg' | 'xl' | 'full';
}) => {
  const { close, open, isOpen } = useDisclosure();

  const darkMode = useColorModeContext();

  React.useEffect(() => {
    if (isDone) {
      close();
    }
  }, [isDone, close]);

  return (
    <>
      {/* @ts-ignore */}
      {React.cloneElement(triggerButton, { onClick: open })}
      <Drawer
        isOpen={isOpen}
        onClose={close}
        title={
          <div
            className={`${
              darkMode[0] ? `text-gray-300` : `text-gray-800`
            } text-m py-3 pt-5 font-bold p-2`}
          >
            {title}
          </div>
        }
        size={size}
        renderFooter={() => (
          <>
            <Button variant="inverse" size="sm" onClick={close}>
              Cancel
            </Button>
            {submitButton}
          </>
        )}
      >
        {children}
      </Drawer>
    </>
  );
};
