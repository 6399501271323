import { axios } from 'lib/axios';
import { useQuery } from 'react-query';

export const getTickers = () => {
  return axios.get('/tapp/stocks/');
};

export const getStocks = prompt => {
  return axios.get(`/tapp/stocks?search_ticker=${prompt}`);
};

// export const useTickers = ({ config } = {}) => {
//   return useQuery({
//     ...config,
//     queryKey: ['tickers'],
//     queryFn: () => getTickers(),
//   });
// };

export const useTickers = ticker => {
  // Define a unique query key for caching
  const queryKey = ['searchResults', ticker];

  // Define the query function that fetches the data
  const queryFn = () => getStocks(ticker);

  // Use React Query's useQuery hook to manage data fetching and caching
  const { data, isLoading, isError, error } = useQuery(queryKey, queryFn, {
    // Additional options can be provided here, such as cache time, retries, etc.
  });

  return { data, isLoading, isError, error };
};
