// @ts-nocheck
import berkeley_skydeck from 'assets/PAD-13_logo.svg'
import microsoft_startups from 'assets/microsoft-for-startups.jpeg'
import aws_activate from 'assets/aws_activate.png'

export const PartnerLogos = () => {
  return (
    <div className="my-12 flex justify-center space-x-8">
      <img
        src={berkeley_skydeck}
        alt="Berkeley SkyDeck"
        className="h-16 w-auto"
      />
      <img
        src={microsoft_startups}
        alt="Microsoft for Startups"
        className="h-16 w-auto"
      />
      <img
        src={aws_activate}
        alt="AWS Activate Program"
        className="h-16 w-auto"
      />
    </div>
  )
}
